import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

/**
 * Dummy implementation for ensuring a user's profile exists.
 * In a production environment, this should query the database and create a profile if missing.
 */
export const ensureProfileExists = async (userId: string, email: string): Promise<void> => {
  // TODO: Implement proper profile check and creation.
  return Promise.resolve();
};

/**
 * Dummy implementation indicating offline mode.
 * Returns false to signify the app is online.
 */
export const isOfflineMode = (): boolean => {
  return false;
};

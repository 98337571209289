import { SystemSettings } from '../types';

export const defaultSystemSettings: SystemSettings = {
  id: crypto.randomUUID(),
  openai_key: '',
  anthropic_key: '',
  deepseek_key: '',
  gemini_key: '',
  active_provider: 'openai',
  model: 'gpt-4',
  system_prompt: 'Generate a 1-2 sentence \'Affirmation of The Day\' based on the user\'s responses. Make it encouraging, empowering, and actionable while acknowledging their situation.',
  daily_limit: 5
};
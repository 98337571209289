import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import { Check, X, CreditCard, Calendar, ArrowRight, Loader2, AlertCircle } from 'lucide-react';
import { APP_CONFIG } from '../../config/app';

interface PricingPageProps {
  onClose: () => void;
}

interface PaymentLinks {
  monthly_standard_link: string;
  yearly_standard_link: string;
  monthly_premium_link: string;
  yearly_premium_link: string;
}

interface SubscriptionDetails {
  plan: 'free' | 'standard' | 'premium';
  status: string;
  startDate: string | null;
  endDate: string | null;
  subscriptionId: string | null;
}

const PricingPage: React.FC<PricingPageProps> = ({ onClose }) => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('monthly');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentLinks, setPaymentLinks] = useState<PaymentLinks | null>(null);
  const [subscription, setSubscription] = useState<SubscriptionDetails>({
    plan: 'free',
    status: 'inactive',
    startDate: null,
    endDate: null,
    subscriptionId: null
  });
  const [transactions, setTransactions] = useState<any[]>([]);
  const [showTransactions, setShowTransactions] = useState(false);
  const [processingPlan, setProcessingPlan] = useState<'free' | 'standard' | 'premium' | null>(null);

  useEffect(() => {
    fetchPaymentLinks();
    fetchUserSubscription();
    fetchTransactions();
  }, []);

  const fetchPaymentLinks = async () => {
    try {
      const { data, error } = await supabase
        .from('system_settings')
        .select('monthly_standard_link, yearly_standard_link, monthly_premium_link, yearly_premium_link')
        .single();

      if (error) throw error;
      
      setPaymentLinks(data as PaymentLinks);
    } catch (err) {
      console.error('Error fetching payment links:', err);
      setError('Unable to load payment options. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserSubscription = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('profiles')
        .select('subscription_plan, subscription_status, subscription_start_date, subscription_end_date, subscription_id')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      
      setSubscription({
        plan: (data?.subscription_plan || 'free') as 'free' | 'standard' | 'premium',
        status: data?.subscription_status || 'inactive',
        startDate: data?.subscription_start_date,
        endDate: data?.subscription_end_date,
        subscriptionId: data?.subscription_id
      });
    } catch (err) {
      console.error('Error fetching user subscription:', err);
    }
  };

  const fetchTransactions = async () => {
    try {
      const { data, error } = await supabase
        .from('subscription_transactions')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      setTransactions(data || []);
    } catch (err) {
      console.error('Error fetching transactions:', err);
    }
  };

  const handleSelectPlan = async (plan: 'free' | 'standard' | 'premium') => {
    setProcessingPlan(plan);
    setError(null);
    
    try {
      if (plan === 'free') {
        // For free plan, just update the user's plan
        await updateUserPlan(plan);
        return;
      }

      // For paid plans, redirect to payment link
      if (!paymentLinks) {
        setError('Payment links are not available. Please try again later.');
        return;
      }

      let paymentLink = '';
      if (plan === 'standard') {
        paymentLink = billingCycle === 'monthly' ? paymentLinks.monthly_standard_link : paymentLinks.yearly_standard_link;
      } else if (plan === 'premium') {
        paymentLink = billingCycle === 'monthly' ? paymentLinks.monthly_premium_link : paymentLinks.yearly_premium_link;
      }

      if (!paymentLink) {
        setError(`Payment link for ${plan} plan (${billingCycle}) is not available.`);
        return;
      }

      // Get user ID to include in payment link
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setError('You must be logged in to update your plan.');
        return;
      }

      // Append user ID to payment link as a query parameter
      const separator = paymentLink.includes('?') ? '&' : '?';
      const paymentLinkWithUserId = `${paymentLink}${separator}user_id=${user.id}&plan=${plan}&cycle=${billingCycle}`;

      // Open payment link in a new tab
      window.open(paymentLinkWithUserId, '_blank');
      
      // Show message about completing payment
      setError('Please complete the payment process in the new tab. Your subscription will be updated once payment is confirmed.');
    } catch (err) {
      console.error('Error selecting plan:', err);
      setError('An error occurred while processing your request. Please try again.');
    } finally {
      setProcessingPlan(null);
    }
  };

  const updateUserPlan = async (plan: 'free' | 'standard' | 'premium') => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setError('You must be logged in to update your plan.');
        return;
      }

      // If downgrading to free, update subscription details
      const updateData: any = { 
        subscription_plan: plan 
      };
      
      if (plan === 'free') {
        updateData.subscription_status = 'inactive';
        updateData.subscription_id = null;
      }

      const { error } = await supabase
        .from('profiles')
        .update(updateData)
        .eq('id', user.id);

      if (error) throw error;
      
      // Update local state
      setSubscription({
        ...subscription,
        plan,
        ...(plan === 'free' ? { 
          status: 'inactive',
          subscriptionId: null
        } : {})
      });
      
      // Show success message
      setError(null);
    } catch (err) {
      console.error('Error updating user plan:', err);
      setError('Failed to update your subscription plan. Please try again.');
    }
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  };

  const isSubscriptionActive = () => {
    if (subscription.plan === 'free') return true;
    
    return subscription.status === 'active' && 
           subscription.endDate && 
           new Date(subscription.endDate) > new Date();
  };

  const getSubscriptionStatusText = () => {
    if (subscription.plan === 'free') return 'Active (Free)';
    
    if (subscription.status === 'active') {
      if (subscription.endDate && new Date(subscription.endDate) > new Date()) {
        return `Active until ${formatDate(subscription.endDate)}`;
      } else {
        return 'Expired';
      }
    }
    
    return 'Inactive';
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-coral-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50" onClick={onClose}>
      <div className="max-w-4xl w-full" onClick={e => e.stopPropagation()}>
        <div className="bg-white/90 backdrop-blur-sm rounded-lg shadow-xl p-8 border border-teal-100">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-teal-800">Subscription Management</h1>
            <button
              onClick={onClose}
              className="text-teal-400 hover:text-teal-600 transition-colors"
              aria-label="Close subscription page"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
          
          {/* Current Subscription Status */}
          <div className="bg-teal-50 p-4 rounded-lg mb-8">
            <h2 className="text-lg font-semibold text-teal-800 mb-2">Current Subscription</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <p className="text-sm text-teal-600">Plan</p>
                <p className="font-medium text-teal-900 capitalize">{subscription.plan}</p>
              </div>
              <div>
                <p className="text-sm text-teal-600">Status</p>
                <p className={`font-medium ${isSubscriptionActive() ? 'text-green-600' : 'text-red-600'}`}>
                  {getSubscriptionStatusText()}
                </p>
              </div>
              <div>
                <p className="text-sm text-teal-600">Daily Affirmations</p>
                <p className="font-medium text-teal-900">
                  {subscription.plan === 'free' ? '1' : 
                   subscription.plan === 'standard' ? '3' : 
                   'Unlimited'}
                </p>
              </div>
            </div>
            
            {subscription.plan !== 'free' && subscription.endDate && (
              <div className="mt-2 text-sm text-teal-700">
                <span className="font-medium">Next billing date:</span> {formatDate(subscription.endDate)}
              </div>
            )}
            
            {transactions.length > 0 && (
              <div className="mt-4">
                <button
                  onClick={() => setShowTransactions(!showTransactions)}
                  className="text-sm text-coral-500 hover:text-coral-600 font-medium flex items-center"
                >
                  {showTransactions ? 'Hide' : 'View'} Transaction History
                  <ArrowRight className={`h-4 w-4 ml-1 transition-transform ${showTransactions ? 'rotate-90' : ''}`} />
                </button>
                
                {showTransactions && (
                  <div className="mt-3 overflow-x-auto">
                    <table className="min-w-full text-sm">
                      <thead>
                        <tr className="border-b border-teal-200">
                          <th className="text-left py-2 px-3">Date</th>
                          <th className="text-left py-2 px-3">Plan</th>
                          <th className="text-left py-2 px-3">Amount</th>
                          <th className="text-left py-2 px-3">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((tx) => (
                          <tr key={tx.id} className="border-b border-teal-100">
                            <td className="py-2 px-3">{new Date(tx.created_at).toLocaleDateString()}</td>
                            <td className="py-2 px-3 capitalize">{tx.subscription_plan} ({tx.billing_cycle})</td>
                            <td className="py-2 px-3">${tx.amount} {tx.currency}</td>
                            <td className="py-2 px-3 capitalize">{tx.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-teal-800 mb-2">Choose Your Plan</h2>
            <p className="text-teal-600 max-w-2xl mx-auto">
              Select the plan that best fits your needs and unlock the full potential of BeliefBuildr.
            </p>
            
            {/* Billing toggle */}
            <div className="flex items-center justify-center mt-6">
              <button
                onClick={() => setBillingCycle('monthly')}
                className={`px-4 py-2 rounded-l-lg ${
                  billingCycle === 'monthly' 
                    ? 'bg-coral-500 text-white' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setBillingCycle('yearly')}
                className={`px-4 py-2 rounded-r-lg ${
                  billingCycle === 'yearly' 
                    ? 'bg-coral-500 text-white' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Yearly <span className="text-xs font-bold">(Save 25%)</span>
              </button>
            </div>
          </div>

          {error && (
            <div className="mb-6 p-3 bg-yellow-50 text-yellow-700 rounded-lg border border-yellow-100 flex items-start">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5" />
              <p>{error}</p>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Free Plan */}
            <div className={`bg-white rounded-lg shadow-md overflow-hidden border-2 ${subscription.plan === 'free' ? 'border-coral-500' : 'border-transparent'}`}>
              <div className="p-6">
                <h3 className="text-xl font-bold text-teal-800 mb-2">Free</h3>
                <div className="mb-4">
                  <span className="text-3xl font-bold text-teal-900">$0</span>
                  <span className="text-teal-600">/forever</span>
                </div>
                <p className="text-teal-600 mb-6">Basic access with limited features</p>
                <button
                  onClick={() => handleSelectPlan('free')}
                  disabled={subscription.plan === 'free' || processingPlan !== null}
                  className={`w-full py-2 px-4 rounded-lg ${
                    subscription.plan === 'free'
                      ? 'bg-teal-100 text-teal-800 cursor-default'
                      : processingPlan !== null
                      ? 'bg-gray-300 text-gray-600 cursor-wait'
                      : 'bg-teal-600 text-white hover:bg-teal-700'
                  }`}
                >
                  {processingPlan === 'free' ? (
                    <span className="flex items-center justify-center">
                      <Loader2 className="animate-spin h-4 w-4 mr-2" />
                      Processing...
                    </span>
                  ) : subscription.plan === 'free' ? 'Current Plan' : 'Select Plan'}
                </button>
              </div>
              <div className="bg-gray-50 p-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">1 affirmation per day</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">Basic affirmation history</span>
                  </li>
                  <li className="flex items-start">
                    <X className="h-5 w-5 text-red-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">Includes ads</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Standard Plan */}
            <div className={`bg-white rounded-lg shadow-md overflow-hidden border-2 ${subscription.plan === 'standard' ? 'border-coral-500' : 'border-transparent'}`}>
              <div className="p-6">
                <h3 className="text-xl font-bold text-teal-800 mb-2">Standard</h3>
                <div className="mb-4">
                  <span className="text-3xl font-bold text-teal-900">
                    {billingCycle === 'monthly' ? '$2.99' : '$26.99'}
                  </span>
                  <span className="text-teal-600">/{billingCycle === 'monthly' ? 'month' : 'year'}</span>
                </div>
                <p className="text-teal-600 mb-6">Enhanced experience, no ads</p>
                <button
                  onClick={() => handleSelectPlan('standard')}
                  disabled={subscription.plan === 'standard' || processingPlan !== null || !paymentLinks}
                  className={`w-full py-2 px-4 rounded-lg ${
                    subscription.plan === 'standard'
                      ? 'bg-teal-100 text-teal-800 cursor-default'
                      : processingPlan !== null
                      ? 'bg-gray-300 text-gray-600 cursor-wait'
                      : 'bg-coral-500 text-white hover:bg-coral-600'
                  }`}
                >
                  {processingPlan === 'standard' ? (
                    <span className="flex items-center justify-center">
                      <Loader2 className="animate-spin h-4 w-4 mr-2" />
                      Processing...
                    </span>
                  ) : subscription.plan === 'standard' ? 'Current Plan' : 'Select Plan'}
                </button>
              </div>
              <div className="bg-gray-50 p-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">3 affirmations per day</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">Full affirmation history</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">No ads</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Premium Plan */}
            <div className={`bg-white rounded-lg shadow-md overflow-hidden border-2 ${subscription.plan === 'premium' ? 'border-coral-500' : 'border-transparent'}`}>
              <div className="absolute top-0 right-0 bg-coral-500 text-white text-xs font-bold px-3 py-1 rounded-bl-lg">
                BEST VALUE
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-teal-800 mb-2">Premium</h3>
                <div className="mb-4">
                  <span className="text-3xl font-bold text-teal-900">
                    {billingCycle === 'monthly' ? '$4.99' : '$44.99'}
                  </span>
                  <span className="text-teal-600">/{billingCycle === 'monthly' ? 'month' : 'year'}</span>
                </div>
                <p className="text-teal-600 mb-6">Ultimate experience with premium features</p>
                <button
                  onClick={() => handleSelectPlan('premium')}
                  disabled={subscription.plan === 'premium' || processingPlan !== null || !paymentLinks}
                  className={`w-full py-2 px-4 rounded-lg ${
                    subscription.plan === 'premium'
                      ? 'bg-teal-100 text-teal-800 cursor-default'
                      : processingPlan !== null
                      ? 'bg-gray-300 text-gray-600 cursor-wait'
                      : 'bg-coral-500 text-white hover:bg-coral-600'
                  }`}
                >
                  {processingPlan === 'premium' ? (
                    <span className="flex items-center justify-center">
                      <Loader2 className="animate-spin h-4 w-4 mr-2" />
                      Processing...
                    </span>
                  ) : subscription.plan === 'premium' ? 'Current Plan' : 'Select Plan'}
                </button>
              </div>
              <div className="bg-gray-50 p-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">Unlimited affirmations</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">Premium AI models</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">No ads</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-teal-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-700">Priority support</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-8 text-center text-sm text-gray-500">
            <p>
              By subscribing, you agree to our Terms of Service and Privacy Policy.
              <br />
              You can cancel your subscription at any time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
import { useState } from "react";
import { Card } from "../ui/card";
import { Button } from "../ui/button";

export function LogsViewer() {
  const [searchTerm, setSearchTerm] = useState("");
  const [logs, setLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const searchLogs = async () => {
    setLoading(true);
    setError(null);
    setLogs([]);
    try {
      if(searchTerm.trim() === ""){
        setError("Please enter a search term.");
        setLoading(false);
        return;
      }
      // Simulate a log search with a delay.
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Simulate search results: if searchTerm is provided, return dummy log; otherwise, no results.
      const results = searchTerm.trim()
        ? [{ id: 1, message: "Log entry matching: " + searchTerm }]
        : [];
      if (results.length === 0) {
        setError("No logs found for the search term.");
      }
      setLogs(results);
    } catch (err: any) {
      setError(err.message || "Error occurred while searching logs.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="p-4">
      <h2 className="text-xl font-bold mb-4">Logs Viewer</h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search logs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded w-full"
        />
      </div>
      <Button onClick={searchLogs} disabled={loading}>
        {loading ? "Searching..." : "Search"}
      </Button>
      {error && <div className="mt-4 text-red-500">{error}</div>}
      {logs.length > 0 && (
        <div className="mt-4">
          <pre className="text-sm">{JSON.stringify(logs, null, 2)}</pre>
        </div>
      )}
    </Card>
  );
}

import CryptoJS from 'crypto-js';

/**
 * Encrypts text using AES encryption with the user's ID as the key
 * @param text Text to encrypt
 * @param userId User ID to use as encryption key
 * @returns Encrypted text
 */
export const encryptText = (text: string, userId: string): string => {
  if (!text || !userId) return '';
  
  // Use the user ID as the encryption key
  // In a production environment, you might want to use a more secure key derivation
  const key = userId;
  
  try {
    return CryptoJS.AES.encrypt(text, key).toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return '';
  }
};

/**
 * Decrypts text that was encrypted with the user's ID as the key
 * @param encryptedText Encrypted text to decrypt
 * @param userId User ID used as encryption key
 * @returns Decrypted text or empty string if decryption fails
 */
export const decryptText = (encryptedText: string, userId: string): string => {
  if (!encryptedText || !userId) return '';
  
  // Use the user ID as the decryption key
  const key = userId;
  
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedText, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Decryption error:', error);
    return '';
  }
};
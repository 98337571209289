import React, { useState, useEffect, useRef } from "react";
import QuestionForm from "./components/QuestionForm";
import AuthForm from "./components/Auth/AuthForm";
import SystemSettings from "./components/Admin/SystemSettings";
import { LogsViewer } from "./components/Admin/LogsViewer";
import { DiagnosePage } from "./components/Admin/DiagnosePage";
import ProfilePage from "./components/Profile/ProfilePage";
import { supabase } from "./utils/supabase";
import { getStoredSettings } from "./utils/storage";
import { User, AlertCircle, LogOut, Settings, History, ChevronDown } from "lucide-react";
import { APP_CONFIG } from "./config/app";

// Dummy implementations for missing helper functions
const checkConnection = async (timeout = 1000, interval = 1000): Promise<boolean> => {
  // In a real environment, implement connectivity checks.
  return Promise.resolve(true);
};

const handleAuthError = async (err: any): Promise<boolean> => {
  // In a real environment, attempt to recover from auth errors.
  return Promise.resolve(true);
};

const ensureProfileExists = async (userId: string, email: string): Promise<void> => {
  // In a real environment, ensure the user's profile exists in the backend.
  return Promise.resolve();
};

const startConnectionKeepAlive = (): () => void => {
  // In a real environment, start a mechanism to keep the connection alive.
  // Return a cleanup function.
  return () => {};
};

const stopConnectionKeepAlive = (): void => {
  // Dummy no-op for stopping the keep-alive mechanism.
};

function App() {
  const [session, setSession] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showProfile, setShowProfile] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [currentView, setCurrentView] = useState<'home' | 'admin' | 'logs' | 'diagnose'>("home");
  const [loggingOut, setLoggingOut] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const [connectionLost, setConnectionLost] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(true); // Always true for now

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const connected = await checkConnection();
        if (!connected) {
          throw new Error("Unable to connect to the server");
        }
        setIsConnected(true);
        setConnectionLost(false);

        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) {
          const recovered = await handleAuthError(sessionError);
          if (!recovered) throw sessionError;
        }
        
        setSession(!!session);
        
        if (session?.user) {
          setUserEmail(session.user.email || null);
          setEmailConfirmed(true);
          if (session.user.email) {
            await ensureProfileExists(session.user.id, session.user.email);
          }
          const settings = await getStoredSettings();
          setIsSuperAdmin(!!settings?.is_superadmin);
          setIsAdmin(!!settings?.is_admin);
          setProfileImageUrl(settings?.profile_image_url || null);
        }

        if (window.location.pathname === "/admin") {
          setCurrentView("admin");
        } else if (window.location.pathname === "/logs") {
          setCurrentView("logs");
        } else {
          setCurrentView("home");
        }
        
        const stopKeepAlive = startConnectionKeepAlive();
        return () => {
          stopKeepAlive();
        };
      } catch (err: any) {
        console.error("Error initializing app:", err);
        setError(err instanceof Error ? err.message : "Unable to connect to the server. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    const init = async () => {
      await initializeApp();
      setLoading(false);
    };
    init();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setSession(!!session);
      if (session?.user) {
        setUserEmail(session.user.email || null);
        setEmailConfirmed(true);
        if (session.user.email) {
          await ensureProfileExists(session.user.id, session.user.email);
        }
        const settings = await getStoredSettings();
        setIsSuperAdmin(!!settings?.is_superadmin);
        setIsAdmin(!!settings?.is_admin);
        setProfileImageUrl(settings?.profile_image_url || null);
      }
    });

    // Fallback in case initialization hangs.
    setTimeout(() => setLoading(false), 5000);

    const handlePopState = () => {
      if (window.location.pathname === "/admin") {
        setCurrentView("admin");
      } else if (window.location.pathname === "/logs") {
        setCurrentView("logs");
      } else {
        setCurrentView("home");
      }
    };
    window.addEventListener("popstate", handlePopState);

    const handleClickOutside = (event: MouseEvent) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target as Node)) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    const connectionMonitor = setInterval(async () => {
      const connected = await checkConnection(1, 500);
      if (!connected && isConnected) {
        setConnectionLost(true);
        setIsConnected(false);
      } else if (connected && !isConnected) {
        setConnectionLost(false);
        setIsConnected(true);
      }
    }, 60000);

    const handleOnline = () => {
      checkConnection().then((connected) => {
        if (connected) {
          setConnectionLost(false);
          setIsConnected(true);
        }
      });
    };

    const handleOffline = () => {
      setConnectionLost(true);
      setIsConnected(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      
      (subscription as any).unsubscribe();
      window.removeEventListener("popstate", handlePopState);
      document.removeEventListener("mousedown", handleClickOutside);
      clearInterval(connectionMonitor);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      stopConnectionKeepAlive();
    };
  }, []);

  const handleRetry = async () => {
    setLoading(true);
    setError(null);
    const connected = await checkConnection();
    if (connected) {
      setIsConnected(true);
      setConnectionLost(false);
      window.location.reload();
    } else {
      setError("Still unable to connect. Please check your internet connection and try again.");
      setLoading(false);
    }
  };

  const handleHomeClick = () => {
    setShowProfile(false);
    setShowProfileMenu(false);
    setCurrentView("home");
    window.history.pushState({}, "", "/");
  };

  const handleAdminClick = () => {
    setShowProfile(false);
    setShowProfileMenu(false);
    setCurrentView("admin");
    window.history.pushState({}, "", "/admin");
  };

  const handleLogsClick = () => {
    setShowProfile(false);
    setShowProfileMenu(false);
    setCurrentView("logs");
    window.history.pushState({}, "", "/logs");
  };

  const handleProfileClick = () => {
    setShowProfile(true);
    setShowProfileMenu(false);
  };

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      setShowProfileMenu(false);
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error("Normal sign out failed, forcing session kill", error);
        await forceKillSession();
        return;
      }
      setSession(false);
      setCurrentView("home");
      window.history.pushState({}, "", "/");
    } catch (err) {
      console.error("Error signing out:", err);
      await forceKillSession();
    } finally {
      setLoggingOut(false);
    }
  };

const forceKillSession = async () => {
  console.log("Force kill session triggered");
  alert("Force Kill Session activated. Reloading page.");
  try {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Supabase sign out error:", error);
    } else {
      console.log("Supabase sign out completed.");
    }
  } catch (e) {
    console.error("Exception during sign out:", e);
  }
  try {
    // Explicitly remove Supabase tokens if any remain, then clear storage.
    window.localStorage.removeItem("supabase.auth.token");
    window.localStorage.removeItem("supabase.auth.refreshToken");
    window.localStorage.clear();
    window.sessionStorage.clear();
    document.cookie.split(";").forEach(cookie => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // Use window.location.replace later to force a reload.
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });
    console.log("Local storage, session storage, and cookies cleared.");
  } catch (e) {
    console.error("Error clearing storage/cookies:", e);
  }
  setSession(false);
  // Use window.location.replace instead of reload for a stronger reload.
  setTimeout(() => {
    console.log("Forcing full page reload now.");
    window.location.replace(window.location.origin);
  }, 500);
};

  const getInitialLetter = () => {
    return userEmail ? userEmail.charAt(0).toUpperCase() : "U";
  };

  const getRandomColor = () => {
    if (!userEmail) return "hsl(200, 70%, 60%)";
    let hash = 0;
    for (let i = 0; i < userEmail.length; i++) {
      hash = userEmail.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = Math.abs(hash % 360);
    return `hsl(${h}, 70%, 60%)`;
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-sand-50 to-sand-100 flex items-center justify-center p-4">
        <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 max-w-md w-full text-center">
          <AlertCircle className="h-12 w-12 text-coral-500 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-teal-800 mb-2">Connection Error</h2>
          <p className="text-teal-600 mb-4">{error}</p>
          <button
            onClick={handleRetry}
            disabled={loading}
            className="bg-coral-500 text-white px-6 py-2 rounded-lg hover:bg-coral-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? "Retrying..." : "Retry Connection"}
          </button>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-sand-50 to-sand-100 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-coral-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-sand-50 to-sand-100 py-6">
      {connectionLost && (
        <div className="fixed top-0 left-0 right-0 bg-red-500 text-white py-2 px-4 text-center z-50">
          <div className="flex items-center justify-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span>Connection lost. </span>
            <button 
              onClick={handleRetry} 
              className="ml-2 underline hover:text-red-100"
            >
              Reconnect
            </button>
          </div>
        </div>
      )}
      <div className="max-w-4xl mx-auto px-4 flex justify-between items-center mb-8">
        <div className="w-12"></div>
        <button
          onClick={handleHomeClick}
          className="flex flex-col items-center group focus:outline-none"
        >
          <img
            src="/beliefbuildr-icon.png"
            alt="BeliefBuildr"
            className="h-16 w-16 mb-2 transform transition-transform group-hover:scale-105"
          />
          <h1 className="text-4xl font-bold text-teal-800 group-hover:text-teal-700 transition-colors">
            BeliefBuildr
          </h1>
        </button>
        {session ? (
          <div className="relative" ref={profileMenuRef}>
            <button
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              className="flex items-center space-x-2"
              aria-label="Profile menu"
            >
              {profileImageUrl ? (
                <img 
                  src={profileImageUrl} 
                  alt="Profile" 
                  className="h-10 w-10 rounded-full object-cover border border-coral-300"
                />
              ) : (
                <div 
                  className="h-10 w-10 rounded-full flex items-center justify-center text-white text-sm font-bold"
                  style={{ backgroundColor: getRandomColor() }}
                >
                  {getInitialLetter()}
                </div>
              )}
            </button>
            {showProfileMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10">
                <button
                  onClick={handleProfileClick}
                  className="w-full text-left px-4 py-2 hover:bg-teal-50 flex items-center space-x-2"
                >
                  <User className="h-4 w-4 text-coral-500" />
                  <span>Profile</span>
                </button>
                {(isAdmin || isSuperAdmin) && (
                  <>
                    <button
                      onClick={handleLogsClick}
                      className={`w-full text-left px-4 py-2 hover:bg-teal-50 flex items-center space-x-2 ${currentView === "logs" ? "bg-teal-50 text-teal-700" : ""}`}
                    >
                      <History className="h-4 w-4 text-coral-500" />
                      <span>Logs</span>
                    </button>
                    {isSuperAdmin && (
                      <>
                        <button
                          onClick={handleAdminClick}
                          className={`w-full text-left px-4 py-2 hover:bg-teal-50 flex items-center space-x-2 ${currentView === "admin" ? "bg-teal-50 text-teal-700" : ""}`}
                        >
                          <Settings className="h-4 w-4 text-coral-500" />
                          <span>Admin</span>
                        </button>
                        <button
                          onClick={() => {
                            setCurrentView("diagnose");
                            window.history.pushState({}, "", "/diagnose");
                          }}
                          className={`w-full text-left px-4 py-2 hover:bg-teal-50 flex items-center space-x-2 ${currentView === "diagnose" ? "bg-teal-50 text-teal-700" : ""}`}
                        >
                          <Settings className="h-4 w-4 text-coral-500" />
                          <span>Diagnose</span>
                        </button>
                      </>
                    )}
                    <div className="border-t border-gray-100 my-1"></div>
                  </>
                )}
                  <button
                    onClick={handleLogout}
                    disabled={loggingOut}
                    className="w-full text-left px-4 py-2 hover:bg-red-50 text-red-500 flex items-center space-x-2"
                  >
                    <LogOut className="h-4 w-4" />
                    <span>{loggingOut ? "Signing out..." : "Sign Out"}</span>
                  </button>
                  <button
                    onClick={forceKillSession}
                    className="w-full text-left px-4 py-2 hover:bg-red-50 text-red-500 flex items-center space-x-2"
                  >
                    <span>Force Kill Session</span>
                  </button>
              </div>
            )}
          </div>
        ) : (
          <div className="w-12"></div>
        )}
      </div>
      <div className="max-w-4xl mx-auto px-4">
        {session ? (
          !emailConfirmed ? (
            <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 max-w-md mx-auto text-center">
              <AlertCircle className="h-12 w-12 text-coral-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold text-teal-800 mb-4">Email Verification Required</h2>
              <p className="text-teal-600 mb-6">
                Please verify your email address to access BeliefBuildr. Check your inbox for a verification link.
              </p>
              <button
                onClick={handleLogout}
                className="bg-coral-500 text-white px-6 py-2 rounded-lg hover:bg-coral-600 transition-colors"
              >
                Sign Out
              </button>
            </div>
          ) : showProfile ? (
            <ProfilePage onClose={() => setShowProfile(false)} />
          ) : currentView === "admin" && isSuperAdmin ? (
            <SystemSettings />
          ) : currentView === "logs" && (isAdmin || isSuperAdmin) ? (
            <LogsViewer />
          ) : currentView === "diagnose" ? (
            <DiagnosePage />
          ) : (
            <QuestionForm />
          )
        ) : (
          <div className="flex justify-center">
            <AuthForm onSuccess={() => setSession(true)} />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;

import React, { useRef, useEffect } from 'react';

interface AffirmationImageProps {
  affirmation: string;
  onImageGenerated: (dataUrl: string) => void;
}

const AffirmationImage: React.FC<AffirmationImageProps> = ({ affirmation, onImageGenerated }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas dimensions for 9:16 aspect ratio (phone screen)
    canvas.width = 1080;
    canvas.height = 1920;

    // Create gradient background
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, '#f8f4f0'); // Light sand color
    gradient.addColorStop(1, '#e6d5c3'); // Darker sand color
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Add decorative elements
    ctx.fillStyle = 'rgba(255, 77, 55, 0.1)'; // Coral color with transparency
    ctx.beginPath();
    ctx.arc(canvas.width * 0.8, canvas.height * 0.2, 300, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = 'rgba(0, 128, 128, 0.1)'; // Teal color with transparency
    ctx.beginPath();
    ctx.arc(canvas.width * 0.2, canvas.height * 0.8, 300, 0, Math.PI * 2);
    ctx.fill();

    // Add logo/branding
    ctx.fillStyle = '#ff4d37'; // Coral color
    ctx.font = 'bold 40px Arial, sans-serif';
    ctx.textAlign = 'center';
    ctx.fillText('BeliefBuildr.com', canvas.width / 2, canvas.height - 100);

    // Add affirmation text
    ctx.fillStyle = '#2d6a6a'; // Teal color
    ctx.font = 'bold 60px Arial, sans-serif';
    ctx.textAlign = 'center';

    // Word wrap the affirmation text
    const words = affirmation.split(' ');
    const lines = [];
    let currentLine = '';
    const maxWidth = canvas.width * 0.8;

    for (let i = 0; i < words.length; i++) {
      const testLine = currentLine + words[i] + ' ';
      const metrics = ctx.measureText(testLine);
      
      if (metrics.width > maxWidth && i > 0) {
        lines.push(currentLine);
        currentLine = words[i] + ' ';
      } else {
        currentLine = testLine;
      }
    }
    lines.push(currentLine);

    // Draw the wrapped text
    const lineHeight = 80;
    const totalTextHeight = lines.length * lineHeight;
    let y = (canvas.height - totalTextHeight) / 2;

    for (let i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], canvas.width / 2, y);
      y += lineHeight;
    }

    // Add decorative quotation marks
    ctx.fillStyle = 'rgba(255, 77, 55, 0.3)'; // Coral color with transparency
    ctx.font = 'bold 120px Arial, sans-serif';
    ctx.fillText('"', canvas.width / 2 - maxWidth / 2 - 20, (canvas.height - totalTextHeight) / 2 - 40);
    ctx.fillText('"', canvas.width / 2 + maxWidth / 2 + 20, y - 40);

    // Convert canvas to data URL and pass it to the parent component
    const dataUrl = canvas.toDataURL('image/png');
    onImageGenerated(dataUrl);
  }, [affirmation]);

  return <canvas ref={canvasRef} style={{ display: 'none' }} />;
};

export default AffirmationImage;
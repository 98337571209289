import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, Copy, Check, Download, Image, X } from 'lucide-react';
import { initialQuestions } from '../config/questions';
import { getStoredSettings, saveSettings, getDailyUsage, incrementDailyUsage, getSystemSettings, saveAffirmation, getLastAffirmation } from '../utils/storage';
import { generateAffirmation } from '../utils/ai';
import { decryptText } from '../utils/crypto';
import { supabase } from '../utils/supabase';
import { getDailyLimitByPlan } from '../utils/subscription';
import type { UserResponse, UserSettings, SystemSettings } from '../types';
import AffirmationImage from './AffirmationImage';

const QuestionForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [responses, setResponses] = useState<UserResponse>({
    hopesWishesGoals: '',
    obstacles: '',
    feelingToday: ''
  });
  const [affirmation, setAffirmation] = useState<string>('');
  const [settings, setSettings] = useState<UserSettings | null>(null);
  const [systemSettings, setSystemSettings] = useState<SystemSettings | null>(null);
  const [dailyUsage, setDailyUsage] = useState(0);
  const [dailyLimit, setDailyLimit] = useState(1); // Default to free plan limit
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const [isGeneratingNew, setIsGeneratingNew] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState<'free' | 'standard' | 'premium'>('free');
  const [affirmationImageUrl, setAffirmationImageUrl] = useState<string | null>(null);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const imagePreviewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [storedSettings, sysSettings, usage] = await Promise.all([
          getStoredSettings(),
          getSystemSettings(),
          getDailyUsage()
        ]);

        if (storedSettings) {
          setSettings(storedSettings);
          setResponses(prev => ({
            ...prev,
            hopesWishesGoals: storedSettings.hopes_wishes_goals || '',
            obstacles: storedSettings.obstacles || ''
          }));
          
          // Set subscription plan
          setSubscriptionPlan((storedSettings.subscription_plan as 'free' | 'standard' | 'premium') || 'free');
          
          // Set daily limit based on subscription plan
          const limit = getDailyLimitByPlan(storedSettings.subscription_plan);
          setDailyLimit(limit);
        }

        if (sysSettings) {
          setSystemSettings(sysSettings);
        }

        setDailyUsage(usage);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load your settings. Please try again.';
        setError(errorMessage);
      }
    };
    
    loadInitialData();

    // Close image preview when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (imagePreviewRef.current && !imagePreviewRef.current.contains(event.target as Node)) {
        setShowImagePreview(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setError(null);
    }
  };

  const handleNext = () => {
    const currentResponse = responses[initialQuestions[currentStep].tag as keyof UserResponse];
    if (!currentResponse.trim()) {
      setError('Please provide a response before continuing.');
      return;
    }

    if (currentStep < initialQuestions.length - 1) {
      setCurrentStep(currentStep + 1);
      setError(null);
    }
  };

  const handleInputChange = (value: string) => {
    const currentQuestion = initialQuestions[currentStep];
    setResponses(prev => ({
      ...prev,
      [currentQuestion.tag]: value
    }));
    setError(null);
  };

  const handleSubmit = async () => {
    const currentResponse = responses[initialQuestions[currentStep].tag as keyof UserResponse];
    if (!currentResponse.trim()) {
      setError('Please provide a response before generating an affirmation.');
      return;
    }

    if (dailyUsage >= dailyLimit) {
      setError('You have reached your daily limit for affirmations. Please try again tomorrow or upgrade your plan.');
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      await saveSettings(responses.hopesWishesGoals, responses.obstacles);
      
      // Generate the affirmation
      await generateUniqueAffirmation();
      
      await incrementDailyUsage();
      setDailyUsage(prev => prev + 1);
    } catch (err) {
      const errorMessage = err instanceof Error 
        ? err.message 
        : 'An unexpected error occurred. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
      setIsGeneratingNew(false);
    }
  };

  const generateUniqueAffirmation = async () => {
    // Get the last affirmation
    const lastAffirmationEntry = await getLastAffirmation();
    let lastAffirmationText = '';
    
    if (lastAffirmationEntry) {
      // Get the user ID for decryption
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        lastAffirmationText = decryptText(lastAffirmationEntry.encrypted_content, user.id);
      }
    }
    
    // Generate a new affirmation
    let generatedAffirmation = await generateAffirmation(responses);
    
    // Check if it's the same as the last one
    if (lastAffirmationText && generatedAffirmation.trim() === lastAffirmationText.trim()) {
      setIsGeneratingNew(true);
      // Try up to 3 more times to get a different affirmation
      for (let i = 0; i < 3; i++) {
        generatedAffirmation = await generateAffirmation(responses);
        if (generatedAffirmation.trim() !== lastAffirmationText.trim()) {
          break;
        }
      }
    }
    
    if (!generatedAffirmation) {
      throw new Error('No affirmation was generated. Please try again.');
    }
    
    setAffirmation(generatedAffirmation);
    
    // Save the affirmation to history
    await saveAffirmation(generatedAffirmation);
    
    return generatedAffirmation;
  };

  const handleStartOver = () => {
    // Only allow starting over if we haven't reached the daily limit
    if (dailyUsage >= dailyLimit) {
      setError('You have reached your daily limit for affirmations. Please try again tomorrow or upgrade your plan.');
      return;
    }
    
    setCurrentStep(0);
    setAffirmation('');
    setError(null);
    setAffirmationImageUrl(null);
    setShowImagePreview(false);
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(affirmation);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      setError('Failed to copy to clipboard');
    }
  };

  const handleImageGenerated = (dataUrl: string) => {
    setAffirmationImageUrl(dataUrl);
  };

  const handleDownloadImage = () => {
    if (!affirmationImageUrl) return;
    
    const link = document.createElement('a');
    link.href = affirmationImageUrl;
    link.download = `affirmation-${new Date().toISOString().slice(0, 10)}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShowImagePreview = () => {
    setShowImagePreview(true);
  };

  const remainingAffirmations = dailyLimit - dailyUsage;
  const hasReachedLimit = dailyUsage >= dailyLimit;
  const isPremium = subscriptionPlan === 'premium';

  if (affirmation) {
    return (
      <div className="max-w-2xl mx-auto p-6">
        {/* Hidden component to generate the image */}
        <AffirmationImage 
          affirmation={affirmation} 
          onImageGenerated={handleImageGenerated} 
        />

        <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 mb-6 border border-teal-100">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-2xl font-bold text-teal-700">Today's Affirmation</h2>
            <div className="flex space-x-2">
              <button
                onClick={handleCopyToClipboard}
                className="text-teal-600 hover:text-teal-800 transition-colors p-2 rounded-lg hover:bg-teal-50"
                title="Copy affirmation"
              >
                {copied ? <Check className="h-5 w-5" /> : <Copy className="h-5 w-5" />}
              </button>
              
              {isPremium && affirmationImageUrl && (
                <>
                  <button
                    onClick={handleShowImagePreview}
                    className="text-teal-600 hover:text-teal-800 transition-colors p-2 rounded-lg hover:bg-teal-50"
                    title="Preview as image"
                  >
                    <Image className="h-5 w-5" />
                  </button>
                  <button
                    onClick={handleDownloadImage}
                    className="text-teal-600 hover:text-teal-800 transition-colors p-2 rounded-lg hover:bg-teal-50"
                    title="Download as image"
                  >
                    <Download className="h-5 w-5" />
                  </button>
                </>
              )}
            </div>
          </div>
          <p className="text-xl leading-relaxed text-teal-900 italic">{affirmation}</p>
          
          {isPremium && (
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleShowImagePreview}
                className="text-sm text-coral-500 hover:text-coral-600 flex items-center"
              >
                <Image className="h-4 w-4 mr-1" />
                Preview as image
              </button>
            </div>
          )}
        </div>
        <button
          onClick={handleStartOver}
          disabled={hasReachedLimit}
          className={`w-full py-3 px-6 rounded-lg shadow-md transition-colors ${
            hasReachedLimit
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-coral-500 hover:bg-coral-600 hover:shadow-lg'
          } text-white`}
        >
          Start Over
        </button>
        <p className="text-sm text-teal-600 mt-4 text-center">
          Daily affirmations remaining: {remainingAffirmations}
          {hasReachedLimit && (
            <span className="block text-coral-600 mt-1">
              You have reached your daily limit. {subscriptionPlan === 'free' ? 'Consider upgrading your plan for more affirmations.' : 'Please try again tomorrow.'}
            </span>
          )}
        </p>
        {subscriptionPlan === 'free' && hasReachedLimit && (
          <div className="mt-4 text-center">
            <button
              onClick={() => window.location.href = '/profile'}
              className="text-coral-500 hover:text-coral-600 font-medium underline"
            >
              Upgrade your plan
            </button>
          </div>
        )}
        
        {/* Image Preview Modal */}
        {showImagePreview && affirmationImageUrl && (
          <div className="fixed inset-0 bg-black/70 flex items-center justify-center p-4 z-50">
            <div 
              ref={imagePreviewRef}
              className="bg-white rounded-lg shadow-xl p-4 max-w-md w-full"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-teal-800">Affirmation Image</h3>
                <button 
                  onClick={() => setShowImagePreview(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              <div className="mb-4 overflow-hidden rounded-lg shadow-md">
                <img 
                  src={affirmationImageUrl} 
                  alt="Affirmation" 
                  className="w-full h-auto"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleDownloadImage}
                  className="bg-coral-500 text-white px-4 py-2 rounded-lg hover:bg-coral-600 transition-colors flex items-center"
                >
                  <Download className="h-4 w-4 mr-2" />
                  Download
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const currentQuestion = initialQuestions[currentStep];

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 border border-teal-100">
        <div className="flex items-center mb-2">
          {currentStep > 0 && (
            <button
              onClick={handleBack}
              className="mr-4 text-teal-600 hover:text-teal-800"
            >
              <ArrowLeft size={24} />
            </button>
          )}
          <h2 className="text-2xl font-bold text-teal-800">
            {currentQuestion.text}
          </h2>
        </div>
        
        {currentStep === 0 && (
          <p className="text-sm text-teal-600 mb-4 italic">
            This relates to your life. Feel free to go wild.
          </p>
        )}
        
        <textarea
          value={responses[currentQuestion.tag as keyof UserResponse]}
          onChange={(e) => handleInputChange(e.target.value)}
          className="w-full p-4 border border-teal-200 rounded-lg mb-6 min-h-[150px] bg-white/50 focus:ring-2 focus:ring-coral-300 focus:border-coral-300 outline-none transition-all"
          placeholder="Type your response here..."
        />

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-100">
            {error}
          </div>
        )}

        {isGeneratingNew && (
          <div className="mb-4 p-3 bg-blue-50 text-blue-700 rounded-lg border border-blue-100">
            Generating a unique affirmation different from your last one...
          </div>
        )}

        <div className="flex justify-between">
          {currentStep < initialQuestions.length - 1 ? (
            <button
              onClick={handleNext}
              disabled={!responses[currentQuestion.tag as keyof UserResponse].trim()}
              className={`bg-coral-500 text-white py-2 px-6 rounded-lg transition-colors shadow-md ${
                !responses[currentQuestion.tag as keyof UserResponse].trim()
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-coral-600 hover:shadow-lg'
              }`}
            >
              Next
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              disabled={isLoading || !responses[currentQuestion.tag as keyof UserResponse].trim() || hasReachedLimit}
              className={`bg-coral-500 text-white py-2 px-6 rounded-lg transition-colors shadow-md ${
                isLoading || !responses[currentQuestion.tag as keyof UserResponse].trim() || hasReachedLimit
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-coral-600 hover:shadow-lg'
              }`}
            >
              {isLoading ? 'Generating...' : 'Generate Affirmation'}
            </button>
          )}
        </div>

        <p className="text-sm text-teal-600 mt-4">
          Daily affirmations remaining: {remainingAffirmations}
          {hasReachedLimit && (
            <span className="block text-coral-600 mt-1">
              You have reached your daily limit. {subscriptionPlan === 'free' ? 'Consider upgrading your plan for more affirmations.' : 'Please try again tomorrow.'}
            </span>
          )}
        </p>
        
        {subscriptionPlan === 'free' && hasReachedLimit && (
          <div className="mt-4 text-center">
            <button
              onClick={() => window.location.href = '/profile'}
              className="text-coral-500 hover:text-coral-600 font-medium underline"
            >
              Upgrade your plan
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionForm;
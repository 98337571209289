import { supabase } from "./supabase";

/**
 * Diagnose potential issues with user creation.
 * This function returns diagnostic information related to Supabase configuration and environment.
 * It accepts an optional onProgress callback to provide step-by-step progress updates.
 */
export const diagnoseUserCreation = async (onProgress?: (msg: string) => void) => {
  onProgress && onProgress("Step 1: Starting diagnosis...");
  // Simulate asynchronous delay for diagnosis
  await new Promise((resolve) => setTimeout(resolve, 500));

  onProgress && onProgress("Step 2: Retrieving session information...");
  // Retrieve session information from Supabase
  const sessionResult = await supabase.auth.getSession();

  onProgress && onProgress("Step 3: Processing diagnostic data...");
  const diagnostics = {
    status: "ok",
    message: "User creation diagnostic ran successfully.",
    supabaseUrl: import.meta.env.VITE_SUPABASE_URL || "VITE_SUPABASE_URL is missing",
    hasServiceKey: Boolean(import.meta.env.VITE_SUPABASE_SERVICE_KEY),
    anonKey: import.meta.env.VITE_SUPABASE_ANON_KEY || "VITE_SUPABASE_ANON_KEY is missing",
    session: sessionResult.data.session,
    timestamp: new Date().toISOString()
  };

  onProgress && onProgress("Step 4: Diagnosis completed.");
  return diagnostics;
};

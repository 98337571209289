/**
 * Utility functions for working with IP addresses
 */

/**
 * Get the client's IP address
 * 
 * In a real production environment, this would be implemented server-side
 * For client-side implementation, we'll use a combination of methods:
 * 1. Try to use WebRTC to get local IP addresses
 * 2. Fall back to a placeholder if WebRTC is not available
 * 
 * Note: This is not 100% reliable and is meant as a best-effort approach
 * for development/debugging purposes
 */
export const getClientIpAddress = async (): Promise<{ ipv4: string | null, ipv6: string | null }> => {
  try {
    // Try to get IP addresses using WebRTC
    const ipAddresses = await getIpAddressesViaWebRTC();
    
    // Return the first IPv4 and IPv6 addresses found
    return {
      ipv4: ipAddresses.find(ip => isIPv4(ip)) || null,
      ipv6: ipAddresses.find(ip => isIPv6(ip)) || null
    };
  } catch (error) {
    console.warn('Failed to get IP addresses:', error);
    
    // Fall back to a placeholder
    return {
      ipv4: '127.0.0.1',
      ipv6: '::1'
    };
  }
};

/**
 * Get IP addresses using WebRTC
 * This uses the WebRTC API to get local IP addresses
 */
const getIpAddressesViaWebRTC = (): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    try {
      // Check if RTCPeerConnection is available
      if (typeof RTCPeerConnection === 'undefined') {
        return reject(new Error('RTCPeerConnection not available'));
      }
      
      const ipAddresses: string[] = [];
      const pc = new RTCPeerConnection({
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
      });
      
      // Create a data channel to trigger ICE gathering
      pc.createDataChannel('');
      
      // Listen for ICE candidates
      pc.onicecandidate = (event) => {
        if (!event.candidate) {
          // ICE gathering is complete
          pc.close();
          resolve(ipAddresses);
          return;
        }
        
        // Extract IP address from candidate string
        const candidateStr = event.candidate.candidate;
        const ipMatch = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/i.exec(candidateStr);
        
        if (ipMatch && ipMatch[1]) {
          const ip = ipMatch[1];
          
          // Only add unique IP addresses
          if (!ipAddresses.includes(ip)) {
            ipAddresses.push(ip);
          }
        }
      };
      
      // Create an offer to start ICE gathering
      pc.createOffer()
        .then(offer => pc.setLocalDescription(offer))
        .catch(error => {
          pc.close();
          reject(error);
        });
      
      // Set a timeout in case ICE gathering takes too long
      setTimeout(() => {
        pc.close();
        resolve(ipAddresses);
      }, 1000);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Check if an IP address is IPv4
 */
const isIPv4 = (ip: string): boolean => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);
};

/**
 * Check if an IP address is IPv6
 */
const isIPv6 = (ip: string): boolean => {
  return /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/.test(ip);
};

import { supabase } from './supabase';
import { log } from './storage';

/**
 * Checks if a user's subscription is active
 * @param userId User ID to check
 * @returns Boolean indicating if subscription is active
 */
export const isSubscriptionActive = async (userId: string): Promise<boolean> => {
  try {
    const { data, error } = await supabase.rpc('is_subscription_active', { user_id: userId });
    
    if (error) {
      log('Error checking subscription status:', { error: error.message });
      return false;
    }
    
    return !!data;
  } catch (err) {
    log('Exception in isSubscriptionActive:', { error: err instanceof Error ? err.message : 'Unknown error' });
    return false;
  }
};

/**
 * Gets the daily limit based on subscription plan
 * @param plan Subscription plan (free, standard, premium)
 * @returns Number of daily affirmations allowed
 */
export const getDailyLimitByPlan = (plan: string | null): number => {
  switch (plan) {
    case 'premium':
      return 999; // Unlimited (practically)
    case 'standard':
      return 3;
    case 'free':
    default:
      return 1;
  }
};

/**
 * Processes a webhook callback from payment processor
 * @param data Payment data from webhook
 * @returns Success status
 */
export const processPaymentWebhook = async (data: any): Promise<boolean> => {
  try {
    const { error } = await supabase.rpc('handle_subscription_webhook', {
      payment_data: data
    });
    
    if (error) {
      log('Error processing payment webhook:', { error: error.message });
      return false;
    }
    
    return true;
  } catch (err) {
    log('Exception in processPaymentWebhook:', { error: err instanceof Error ? err.message : 'Unknown error' });
    return false;
  }
};

/**
 * Gets a user's subscription details
 * @param userId User ID to check
 * @returns Subscription details
 */
export const getSubscriptionDetails = async (userId: string) => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('subscription_plan, subscription_status, subscription_start_date, subscription_end_date, subscription_id')
      .eq('id', userId)
      .single();
    
    if (error) {
      log('Error fetching subscription details:', { error: error.message });
      return null;
    }
    
    return data;
  } catch (err) {
    log('Exception in getSubscriptionDetails:', { error: err instanceof Error ? err.message : 'Unknown error' });
    return null;
  }
};

/**
 * Gets a user's transaction history
 * @param userId User ID to check
 * @returns Array of transactions
 */
export const getTransactionHistory = async (userId: string) => {
  try {
    const { data, error } = await supabase
      .from('subscription_transactions')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });
    
    if (error) {
      log('Error fetching transaction history:', { error: error.message });
      return [];
    }
    
    return data || [];
  } catch (err) {
    log('Exception in getTransactionHistory:', { error: err instanceof Error ? err.message : 'Unknown error' });
    return [];
  }
};
import { supabase } from './supabase';
import type { UserSettings, SystemSettings } from '../types';
import { defaultSystemSettings } from '../config/system';
import { encryptText } from './crypto';
import { APP_CONFIG } from '../config/app';
import { getDailyLimitByPlan } from './subscription';
import { getClientIpAddress } from './ip-utils';

// Cache system settings to avoid frequent fetches
let cachedSystemSettings: SystemSettings | null = null;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Cache session to avoid frequent fetches
let sessionPromise: Promise<any> | null = null;
let sessionTimeout: NodeJS.Timeout | null = null;
const SESSION_CACHE_DURATION = 30 * 1000; // 30 seconds

// Cache profile data
let profilePromise: Promise<any> | null = null;
let profileTimeout: NodeJS.Timeout | null = null;
const PROFILE_CACHE_DURATION = 30 * 1000; // 30 seconds

// Cache user email to avoid repeated lookups
const userEmailCache: Record<string, string> = {};

// Debug logging
const debugLogs: string[] = [];
const MAX_LOGS = 1000;

// Track connection status to avoid repeated failed requests
let isSupabaseConnected = true;
let lastConnectionCheck = 0;
const CONNECTION_CHECK_INTERVAL = 30 * 1000; // 30 seconds


export const log = async (message: string, data?: any, ipAddresses?: { ipv4: string | null, ipv6: string | null }) => {
  const timestamp = new Date().toISOString();
  const buildInfo = `[v${APP_CONFIG.version}]`;
  const logMessage = `[${timestamp}] ${buildInfo} ${message}${data ? ' ' + JSON.stringify(data, null, 2) : ''}`;
  
  // Store in memory for immediate access
  debugLogs.push(logMessage);
  if (debugLogs.length > MAX_LOGS) {
    debugLogs.shift();
  }
  
  // Log to console
  console.log(logMessage);

  // Get client IP addresses if not provided
  const { ipv4, ipv6 } = ipAddresses || await getClientIpAddress();

  // Only attempt to log to database if we think we're connected
  // or if it's been a while since we last checked
  if (isSupabaseConnected || Date.now() - lastConnectionCheck > CONNECTION_CHECK_INTERVAL) {
    try {
      // Get current user info
      const { data: { user } } = await supabase.auth.getUser();
      let userEmail = null;
      let userId = null;
      
      if (user) {
        userId = user.id;
        
        // Try to get email from cache first
        if (userEmailCache[user.id]) {
          userEmail = userEmailCache[user.id];
        } else {
          // Try to get email from auth user
          userEmail = user.email;
          
          // If not available, try to get from profile
          if (!userEmail) {
            const { data: profile } = await supabase
              .from('profiles')
              .select('email')
              .eq('id', user.id)
              .single();
              
            if (profile?.email) {
              userEmail = profile.email;
            }
          }
          
          // Cache the email if found
          if (userEmail) {
            userEmailCache[user.id] = userEmail;
          }
        }
      }
      
      // Direct SQL insert to bypass RLS issues
      let error = null;
      try {
        // Use a separate try-catch for the RPC call to handle TypeScript issues
        const result = await supabase.rpc(
          'insert_system_log',
          { 
            p_user_id: userId,
            p_user_email: userEmail,
            p_message: `${buildInfo} ${message}`,
            p_data: data ? JSON.stringify(data) : null,
            p_ip_address: ipv4,
            p_ip_address_v6: ipv6
          }
        );
        error = result.error;
      } catch (rpcError) {
        console.error('RPC call failed:', rpcError);
        error = rpcError;
      }

      if (error) {
        console.error('Failed to save log to database:', error);
        
        // If we get a connection error, mark as disconnected
        if (error.message.includes('Failed to fetch') || 
            error.message.includes('NetworkError') ||
            error.message.includes('network error')) {
          isSupabaseConnected = false;
          lastConnectionCheck = Date.now();
        }
      } else {
        // If successful, mark as connected
        isSupabaseConnected = true;
        lastConnectionCheck = Date.now();
      }
    } catch (err) {
      console.error('Error logging to database:', err);
      
      // Mark as disconnected on exception
      isSupabaseConnected = false;
      lastConnectionCheck = Date.now();
    }
  }
};

export const getLogs = () => [...debugLogs];

// Get cached session or fetch new one
const getSession = async () => {
  if (!sessionPromise) {
    log('getSession: Fetching new session');
    sessionPromise = supabase.auth.getSession();
    
    // Clear the promise after cache duration
    if (sessionTimeout) clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(() => {
      sessionPromise = null;
    }, SESSION_CACHE_DURATION);
  }

  try {
    const { data: { session }, error } = await sessionPromise;
    if (error) throw error;
    return session;
  } catch (err) {
    sessionPromise = null; // Clear failed promise
    throw err;
  }
};

// Get cached profile or fetch new one
const getProfile = async (userId: string) => {
  if (!profilePromise) {
    log('getProfile: Fetching new profile');
    profilePromise = supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .maybeSingle();
    
    // Clear the promise after cache duration
    if (profileTimeout) clearTimeout(profileTimeout);
    profileTimeout = setTimeout(() => {
      profilePromise = null;
    }, PROFILE_CACHE_DURATION);
  }

  try {
    const { data, error } = await profilePromise;
    if (error) throw error;
    return data;
  } catch (err) {
    profilePromise = null; // Clear failed promise
    throw err;
  }
};

export const getSystemSettings = async (): Promise<SystemSettings | null> => {
  try {
    if (cachedSystemSettings && (Date.now() - lastFetchTime < CACHE_DURATION)) {
      return cachedSystemSettings;
    }

    const { data, error } = await supabase
      .from('system_settings')
      .select('*')
      .maybeSingle();

    if (error) {
      if (error.code === 'PGRST116') {
        const { error: insertError } = await supabase
          .from('system_settings')
          .insert(defaultSystemSettings);
        
        if (insertError) throw insertError;
        cachedSystemSettings = defaultSystemSettings;
        lastFetchTime = Date.now();
        return defaultSystemSettings;
      }
      throw error;
    }

    cachedSystemSettings = data || defaultSystemSettings;
    lastFetchTime = Date.now();
    return cachedSystemSettings;
  } catch (err) {
    log(`getSystemSettings error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    return defaultSystemSettings;
  }
};

export const getStoredSettings = async () => {
  try {
    const session = await getSession();
    if (!session?.user) return null;

    const profile = await getProfile(session.user.id);
    if (!profile) {
      const newProfile = {
        id: session.user.id,
        hopes_wishes_goals: '',
        obstacles: '',
        is_superadmin: false,
        is_admin: false,
        subscription_plan: 'free'
      };

      const { error: insertError } = await supabase
        .from('profiles')
        .insert(newProfile);

      if (insertError) throw insertError;
      return newProfile;
    }

    return profile;
  } catch (err) {
    log(`getStoredSettings error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    return null;
  }
};

export const saveSettings = async (hopesWishesGoals: string, obstacles: string) => {
  try {
    const session = await getSession();
    if (!session?.user) throw new Error('Not authenticated');

    const { error } = await supabase
      .from('profiles')
      .upsert({
        id: session.user.id,
        hopes_wishes_goals: hopesWishesGoals,
        obstacles: obstacles,
        updated_at: new Date().toISOString()
      });

    if (error) throw error;
    
    // Clear profile cache since we updated it
    profilePromise = null;
  } catch (err) {
    log(`saveSettings error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    throw err;
  }
};

export const saveAffirmation = async (affirmation: string) => {
  try {
    const session = await getSession();
    if (!session?.user) throw new Error('Not authenticated');

    // Encrypt the affirmation using the user's ID as the key
    const encryptedContent = encryptText(affirmation, session.user.id);
    
    if (!encryptedContent) {
      throw new Error('Failed to encrypt affirmation');
    }

    const { error } = await supabase
      .from('affirmation_history')
      .insert({
        user_id: session.user.id,
        encrypted_content: encryptedContent
      });

    if (error) throw error;
    
    return true;
  } catch (err) {
    log(`saveAffirmation error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    throw err;
  }
};

export const getAffirmationHistory = async () => {
  try {
    const session = await getSession();
    if (!session?.user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('affirmation_history')
      .select('*')
      .eq('user_id', session.user.id)
      .order('created_at', { ascending: false });

    if (error) throw error;
    
    return data || [];
  } catch (err) {
    log(`getAffirmationHistory error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    throw err;
  }
};

export const getLastAffirmation = async () => {
  try {
    const session = await getSession();
    if (!session?.user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('affirmation_history')
      .select('*')
      .eq('user_id', session.user.id)
      .order('created_at', { ascending: false })
      .limit(1)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        // No affirmation found
        return null;
      }
      throw error;
    }
    
    return data;
  } catch (err) {
    log(`getLastAffirmation error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    return null;
  }
};

export const getDailyUsage = async () => {
  try {
    const session = await getSession();
    if (!session?.user) return 0;

    const [systemSettings, profile] = await Promise.all([
      getSystemSettings(),
      getProfile(session.user.id)
    ]);

    if (!systemSettings || !profile) return 0;

    // Check subscription plan and adjust daily limit
    let dailyLimit = systemSettings.daily_limit;
    if (profile.subscription_plan === 'standard') {
      dailyLimit = 3; // Standard plan: 3 affirmations per day
    } else if (profile.subscription_plan === 'premium') {
      dailyLimit = 999; // Premium plan: unlimited (practically)
    } else {
      dailyLimit = 1; // Free plan: 1 affirmation per day
    }

    const timezone = profile.timezone || 'UTC';
    const now = new Date();

    // Convert current UTC time to user's timezone for comparison
    const userNow = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
    log(`getDailyUsage: Current time in ${timezone}: ${userNow.toLocaleString()}`);
    log(`getDailyUsage: Current usage count: ${profile.daily_usage_count || 0}`);

    // If no stored date, initialize with today's date in user's timezone
    if (!profile.daily_usage_date) {
      log('getDailyUsage: No stored date, initializing with today');
      const todayInUserTZ = new Date(userNow.toLocaleDateString('en-US'));
      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          daily_usage_count: 0,
          daily_usage_date: todayInUserTZ.toISOString()
        })
        .eq('id', session.user.id);
        
      if (updateError) throw updateError;
      profilePromise = null;
      return 0;
    }

    // Convert stored date to user's timezone for comparison
    const storedDate = new Date(profile.daily_usage_date);
    const userStoredDate = new Date(storedDate.toLocaleString('en-US', { timeZone: timezone }));
    log(`getDailyUsage: Stored date in ${timezone}: ${userStoredDate.toLocaleString()}`);
    
    // Get dates only (ignoring time) in user's timezone
    const userNowDate = new Date(userNow.toLocaleDateString('en-US'));
    const userStoredDateOnly = new Date(userStoredDate.toLocaleDateString('en-US'));

    log(`getDailyUsage: Current date (no time): ${userNowDate.toLocaleString()}`);
    log(`getDailyUsage: Stored date (no time): ${userStoredDateOnly.toLocaleString()}`);
    log(`getDailyUsage: Date comparison result: ${userNowDate.getTime() > userStoredDateOnly.getTime() ? 'New day' : 'Same day'}`);

    if (userNowDate.getTime() > userStoredDateOnly.getTime()) {
      // It's a new day in user's timezone, reset counter
      log('getDailyUsage: New day detected, resetting counter');
      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          daily_usage_count: 0,
          daily_usage_date: userNowDate.toISOString()
        })
        .eq('id', session.user.id);
        
      if (updateError) throw updateError;
      profilePromise = null;
      return 0;
    }

    // Still the same day in user's timezone
    const currentUsage = Math.min(profile.daily_usage_count || 0, dailyLimit);
    log(`getDailyUsage: Same day, returning current usage: ${currentUsage}`);
    return currentUsage;
  } catch (err) {
    log(`getDailyUsage error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    return 0;
  }
};

export const incrementDailyUsage = async () => {
  try {
    const session = await getSession();
    if (!session?.user) throw new Error('Not authenticated');

    const [systemSettings, profile, currentUsage] = await Promise.all([
      getSystemSettings(),
      getProfile(session.user.id),
      getDailyUsage()
    ]);

    if (!systemSettings) throw new Error('Could not fetch system settings');
    if (!profile) throw new Error('Could not fetch user profile');

    // Check subscription plan and adjust daily limit
    let dailyLimit = systemSettings.daily_limit;
    if (profile.subscription_plan === 'standard') {
      dailyLimit = 3; // Standard plan: 3 affirmations per day
    } else if (profile.subscription_plan === 'premium') {
      dailyLimit = 999; // Premium plan: unlimited (practically)
    } else {
      dailyLimit = 1; // Free plan: 1 affirmation per day
    }

    if (currentUsage >= dailyLimit) throw new Error('Daily limit reached');

    log(`incrementDailyUsage: Current usage: ${currentUsage}, incrementing to ${currentUsage + 1}`);

    const { error } = await supabase
      .from('profiles')
      .update({
        daily_usage_count: currentUsage + 1,
        last_affirmation_at: new Date().toISOString()
      })
      .eq('id', session.user.id);

    if (error) throw error;
    profilePromise = null;
    
    log(`incrementDailyUsage: Successfully incremented usage to ${currentUsage + 1}`);
  } catch (err) {
    log(`incrementDailyUsage error: ${err instanceof Error ? err.message : 'Unknown error'}`);
    throw err;
  }
};

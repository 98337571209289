import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import { Settings, Save, Loader2, UserPlus, X, Shield, Info, CreditCard } from 'lucide-react';
import { providerModels } from '../../utils/ai';
import { APP_CONFIG } from '../../config/app';

interface SystemSettings {
  id: string;
  openai_key: string;
  anthropic_key: string;
  deepseek_key: string;
  gemini_key: string;
  active_provider: string;
  model: string;
  system_prompt: string;
  daily_limit: number;
  monthly_standard_link?: string;
  yearly_standard_link?: string;
  monthly_premium_link?: string;
  yearly_premium_link?: string;
}

interface AdminUser {
  user_id: string;
  email: string;
  role_name: string;
}

const defaultSettings: SystemSettings = {
  id: crypto.randomUUID(),
  openai_key: '',
  anthropic_key: '',
  deepseek_key: '',
  gemini_key: '',
  active_provider: 'openai',
  model: 'gpt-4',
  system_prompt: 'Generate a 1-2 sentence \'Affirmation of The Day\' based on the user\'s responses. Make it encouraging, empowering, and actionable while acknowledging their situation.',
  daily_limit: 5
};

export default function SystemSettings() {
  const [settings, setSettings] = useState<SystemSettings>(defaultSettings);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [isAddingAdmin, setIsAddingAdmin] = useState(false);

  useEffect(() => {
    checkSuperAdminStatus();
    loadSettings();
    loadAdminUsers();
  }, []);

  const checkSuperAdminStatus = async () => {
    try {
      const { data, error } = await supabase.rpc('is_superadmin');
      if (error) throw error;
      setIsSuperAdmin(!!data);
    } catch (err) {
      console.error('Error checking superadmin status:', err);
      setIsSuperAdmin(false);
    }
  };

  const loadAdminUsers = async () => {
    if (!isSuperAdmin) return;
    
    try {
      const { data, error } = await supabase.rpc('get_admin_users');
      if (error) throw error;
      setAdminUsers(data || []);
    } catch (err) {
      console.error('Error loading admin users:', err);
    }
  };

  const loadSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('system_settings')
        .select('*')
        .maybeSingle();

      if (error) {
        if (error.code === 'PGRST116') {
          const { error: insertError } = await supabase
            .from('system_settings')
            .insert(defaultSettings);
          
          if (insertError) throw insertError;
          setSettings(defaultSettings);
          return;
        }
        throw error;
      }

      if (data) {
        // Ensure all fields have non-null values
        setSettings({
          ...defaultSettings,
          ...data,
          openai_key: data.openai_key || '',
          anthropic_key: data.anthropic_key || '',
          deepseek_key: data.deepseek_key || '',
          gemini_key: data.gemini_key || '',
          system_prompt: data.system_prompt || defaultSettings.system_prompt,
          daily_limit: data.daily_limit || defaultSettings.daily_limit,
          monthly_standard_link: data.monthly_standard_link || '',
          yearly_standard_link: data.yearly_standard_link || '',
          monthly_premium_link: data.monthly_premium_link || '',
          yearly_premium_link: data.yearly_premium_link || ''
        });
      }
    } catch (err) {
      console.error('Error in loadSettings:', err);
      setError('Failed to load settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isSuperAdmin) {
      setError('You do not have permission to modify system settings.');
      return;
    }

    setIsSaving(true);
    setError(null);
    setSuccess(false);

    try {
      const { error } = await supabase
        .from('system_settings')
        .upsert(settings);

      if (error) throw error;

      setSuccess(true);
      await loadSettings();
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError('Failed to save settings. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSettings(prev => {
      const newSettings = {
        ...prev,
        [name]: name === 'daily_limit' ? Math.max(1, parseInt(value) || 5) : value
      };

      if (name === 'active_provider') {
        newSettings.model = providerModels[value as keyof typeof providerModels][0];
      }

      return newSettings;
    });
  };

  const handleAddAdmin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isSuperAdmin || !newAdminEmail.trim()) return;

    setIsAddingAdmin(true);
    setError(null);

    try {
      const { data: users, error: userError } = await supabase
        .from('auth.users')
        .select('id')
        .eq('email', newAdminEmail)
        .single();

      if (userError) throw new Error('User not found');

      const { data: roles, error: roleError } = await supabase
        .from('roles')
        .select('id')
        .eq('name', 'admin')
        .single();

      if (roleError) throw roleError;

      const { error: assignError } = await supabase
        .from('user_roles')
        .insert({
          user_id: users.id,
          role_id: roles.id
        });

      if (assignError) throw assignError;

      setNewAdminEmail('');
      await loadAdminUsers();
      setSuccess(true);
    } catch (err) {
      console.error('Error adding admin:', err);
      setError('Failed to add admin. Please verify the email and try again.');
    } finally {
      setIsAddingAdmin(false);
    }
  };

  const handleRemoveAdmin = async (userId: string) => {
    if (!isSuperAdmin) return;

    try {
      const { error } = await supabase
        .from('user_roles')
        .delete()
        .eq('user_id', userId);

      if (error) throw error;

      await loadAdminUsers();
      setSuccess(true);
    } catch (err) {
      console.error('Error removing admin:', err);
      setError('Failed to remove admin. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="animate-spin h-8 w-8 text-indigo-600" />
      </div>
    );
  }

  if (!isSuperAdmin) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="text-center">
            <Settings className="h-12 w-12 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Access Denied</h2>
            <p className="text-gray-600">
              You do not have permission to access system settings. Please contact a superadmin.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <Settings className="h-6 w-6 text-indigo-600 mr-2" />
            <h2 className="text-2xl font-bold text-gray-800">System Settings</h2>
          </div>
          <div className="text-xs text-gray-500">
            v{APP_CONFIG.version}
          </div>
        </div>

        {/* Admin Management Section */}
        <div className="mb-8 border-b pb-8">
          <h3 className="text-lg font-semibold text-gray-700 mb-4">Admin Management</h3>
          
          {/* Add New Admin Form */}
          <form onSubmit={handleAddAdmin} className="mb-6">
            <div className="flex gap-4">
              <input
                type="email"
                value={newAdminEmail}
                onChange={(e) => setNewAdminEmail(e.target.value)}
                placeholder="Enter admin email"
                className="flex-1 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <button
                type="submit"
                disabled={isAddingAdmin || !newAdminEmail.trim()}
                className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors flex items-center"
              >
                {isAddingAdmin ? (
                  <Loader2 className="animate-spin h-5 w-5" />
                ) : (
                  <>
                    <UserPlus className="h-5 w-5 mr-2" />
                    Add Admin
                  </>
                )}
              </button>
            </div>
          </form>

          {/* Admin List */}
          <div className="space-y-4">
            {adminUsers.map((user) => (
              <div key={user.user_id} className="flex items-center justify-between bg-gray-50 p-4 rounded-lg">
                <div className="flex items-center">
                  <Shield className={`h-5 w-5 ${user.role_name === 'superadmin' ? 'text-indigo-600' : 'text-gray-600'} mr-3`} />
                  <div>
                    <p className="font-medium text-gray-900">{user.email}</p>
                    <p className="text-sm text-gray-500 capitalize">{user.role_name}</p>
                  </div>
                </div>
                {user.role_name !== 'superadmin' && (
                  <button
                    onClick={() => handleRemoveAdmin(user.user_id)}
                    className="text-red-600 hover:text-red-800 p-2"
                    title="Remove admin"
                  >
                    <X className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* API Keys */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-700">API Keys</h3>
            <div className="grid gap-6">
              {['openai', 'anthropic', 'deepseek', 'gemini'].map((provider) => (
                <div key={provider}>
                  <label className="block text-sm font-medium text-gray-700 mb-1 capitalize">
                    {provider} API Key
                  </label>
                  <input
                    type="password"
                    name={`${provider}_key`}
                    value={settings[`${provider}_key` as keyof SystemSettings]}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Model Settings */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-700">Model Settings</h3>
            <div className="grid gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Active Provider
                </label>
                <select
                  name="active_provider"
                  value={settings.active_provider}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="openai">OpenAI</option>
                  <option value="anthropic">Anthropic (Claude)</option>
                  <option value="deepseek">DeepSeek</option>
                  <option value="gemini">Google Gemini</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Model
                </label>
                <select
                  name="model"
                  value={settings.model}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  {providerModels[settings.active_provider as keyof typeof providerModels]?.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Daily Limit
                </label>
                <input
                  type="number"
                  name="daily_limit"
                  value={settings.daily_limit}
                  onChange={handleChange}
                  min="1"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>
          </div>

          {/* Payment Links */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-700 flex items-center">
              <CreditCard className="h-5 w-5 mr-2 text-indigo-600" />
              Payment Links
            </h3>
            <div className="grid gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Standard Plan - Monthly ($2.99/month)
                </label>
                <input
                  type="text"
                  name="monthly_standard_link"
                  value={settings.monthly_standard_link}
                  onChange={handleChange}
                  placeholder="https://example.com/pay/standard-monthly"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Standard Plan - Yearly ($26.99/year)
                </label>
                <input
                  type="text"
                  name="yearly_standard_link"
                  value={settings.yearly_standard_link}
                  onChange={handleChange}
                  placeholder="https://example.com/pay/standard-yearly"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Premium Plan - Monthly ($4.99/month)
                </label>
                <input
                  type="text"
                  name="monthly_premium_link"
                  value={settings.monthly_premium_link}
                  onChange={handleChange}
                  placeholder="https://example.com/pay/premium-monthly"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Premium Plan - Yearly ($44.99/year)
                </label>
                <input
                  type="text"
                  name="yearly_premium_link"
                  value={settings.yearly_premium_link}
                  onChange={handleChange}
                  placeholder="https://example.com/pay/premium-yearly"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>
          </div>

          {/* System Prompt */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-700">System Prompt</h3>
            <div>
              <textarea
                name="system_prompt"
                value={settings.system_prompt}
                onChange={handleChange}
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
          </div>

          {/* App Version */}
          <div className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-700">App Information</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center">
                <Info className="h-5 w-5 text-indigo-600 mr-2" />
                <span className="font-medium">{APP_CONFIG.name} v{APP_CONFIG.version}</span>
              </div>
              <p className="text-sm text-gray-600 mt-1">
                Build date: {new Date(APP_CONFIG.buildDate).toLocaleDateString()}
              </p>
            </div>
          </div>

          {error && (
            <div className="p-3 bg-red-50 text-red-700 rounded-lg">
              {error}
            </div>
          )}

          {success && (
            <div className="p-3 bg-green-50 text-green-700 rounded-lg">
              Settings saved successfully!
            </div>
          )}

          <button
            type="submit"
            disabled={isSaving}
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors flex items-center justify-center"
          >
            {isSaving ? (
              <Loader2 className="animate-spin" size={20} />
            ) : (
              <>
                <Save className="h-5 w-5 mr-2" />
                Save Settings
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
import { useEffect, useState } from "react";
import { diagnoseUserCreation } from "../../utils/diagnose";
import { Button } from "../ui/button";
import { Card } from "../ui/card";

export function DiagnosePage() {
  const [diagnosis, setDiagnosis] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<string[]>([]);

  const runDiagnosis = async () => {
    setLoading(true);
    setError(null);
    setProgress([]);
    setDiagnosis(null);
    try {
      const result = await diagnoseUserCreation((msg: string) => {
        setProgress((prev) => [...prev, msg]);
      });
      setDiagnosis(result);
    } catch (err: any) {
      setError(err?.message || "Unknown error occurred");
    } finally {
      setLoading(false);
    }
  };

  const downloadDiagnosis = () => {
    const dataStr = "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(diagnosis, null, 2));
    const dt = new Date();
    const filename = `diagnosis_${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,"0")}-${dt.getDate().toString().padStart(2,"0")}_${dt.getHours().toString().padStart(2,"0")}-${dt.getMinutes().toString().padStart(2,"0")}-${dt.getSeconds().toString().padStart(2,"0")}.txt`;
    const anchor = document.createElement("a");
    anchor.setAttribute("href", dataStr);
    anchor.setAttribute("download", filename);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    runDiagnosis();
  }, []);

  return (
    <Card className="p-4">
      <h2 className="text-xl font-bold mb-4">User Creation Diagnosis</h2>
      <p className="mb-4 text-gray-700">Diagnostic Process:</p>
      
      {loading && <p>Running diagnosis...</p>}
      
      {error && (
        <div className="text-red-500 mb-4">
          <p>Error: {error}</p>
        </div>
      )}

      {progress.length > 0 && (
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Progress:</h3>
          <ul className="list-disc list-inside text-gray-700">
            {progress.map((msg, idx) => (
              <li key={idx}>{msg}</li>
            ))}
          </ul>
        </div>
      )}

      {diagnosis !== null ? (
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Final Diagnosis Result:</h3>
          <pre className="text-black bg-gray-100 p-2 rounded">
            {JSON.stringify(diagnosis, null, 2)}
          </pre>
        </div>
      ) : (
        !loading && !error && <p className="text-gray-700">No diagnostic information available.</p>
      )}

      <div className="flex space-x-4">
        <Button onClick={runDiagnosis} disabled={loading}>
          {loading ? "Running..." : "Re-run Diagnosis"}
        </Button>
        {diagnosis !== null && (
          <Button onClick={downloadDiagnosis}>
            Download Diagnosis
          </Button>
        )}
      </div>
    </Card>
  );
}

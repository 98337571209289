import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import type { AuthError } from '../../types';
import { Mail, Lock, ArrowRight, Loader2, AlertCircle, CheckCircle, RefreshCw } from 'lucide-react';
import { log } from '../../utils/storage';
import { ensureProfileExists } from '../../utils/supabase';
import { adminCreateUser } from '../../utils/admin-auth';
import { getClientIpAddress } from '../../utils/ip-utils';
import { APP_CONFIG } from '../../config/app';

interface AuthFormProps {
  onSuccess: () => void;
}

// Serverless function simulation
const createUserServerless = async (email: string, password: string) => {
  // This is a simulation of a serverless function
  // In a real app, this would be a call to a serverless function
  try {
    const { data, error } = await supabase.auth.admin.createUser({
      email,
      password,
      email_confirm: true,
    });
    
    if (error) throw error;
    return { user: data.user };
  } catch (error) {
    throw error;
  }
};

// Create profile serverless simulation
const createProfileServerless = async (userId: string, email: string) => {
  try {
    const { error } = await supabase
      .from('profiles')
      .insert({
        id: userId,
        email,
        subscription_plan: 'free',
        daily_usage_count: 0,
        daily_usage_date: new Date().toISOString(),
        timezone: 'UTC',
        is_superadmin: false,
        is_admin: false,
        updated_at: new Date().toISOString()
      });
    
    if (error) throw error;
    return true;
  } catch (error) {
    throw error;
  }
};

export default function AuthForm({ onSuccess }: AuthFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);
  const [signupEmail, setSignupEmail] = useState('');

  // Countdown timer for resend button
  useEffect(() => {
    if (resendCountdown > 0) {
      const timer = setTimeout(() => {
        setResendCountdown(resendCountdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [resendCountdown]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    
    // Get client IP address for logging
    const ipAddresses = await getClientIpAddress();

    try {
      if (resetPassword) {
        const { error } = await supabase.auth.resetPasswordForEmail(email, {
          redirectTo: `${window.location.origin}/reset-password`,
        });
        
        // Log the reset password attempt with IP address
        log('Reset password attempt', { email }, ipAddresses);
        
        if (error) throw error;
        setResetSent(true);
      } else if (isSignUp) {
        // Log the signup attempt for debugging with IP address
        log('Attempting to sign up user', { email }, ipAddresses);
        
        // Store email for confirmation screen
        setSignupEmail(email);
        
        // Variable to store signup response
        let signupData: any = null;
        
        try {
          // Try multiple approaches to create a user, with fallbacks
          
          // Approach 1: Admin API
          log('Trying admin API for user creation', { email }, ipAddresses);
          try {
            // Attempt to create user with admin API
            const adminData = await adminCreateUser(email, password);
            signupData = adminData;
            
            log('Admin API user creation successful', { 
              userId: adminData.user.id,
              email: adminData.user.email
            }, ipAddresses);
          } catch (adminError) {
            log('Admin API failed, falling back to serverless approach', {
              error: (adminError as Error).message
            }, ipAddresses);
            
            // Approach 2: Serverless function simulation
            try {
              const serverlessData = await createUserServerless(email, password);
              signupData = serverlessData;
              
              log('Serverless user creation successful', { 
                userId: serverlessData.user?.id,
                email: serverlessData.user?.email
              }, ipAddresses);
              
              // If we have a user ID, ensure profile exists
              if (serverlessData.user?.id) {
                await createProfileServerless(serverlessData.user.id, email);
              }
            } catch (serverlessError) {
              log('Serverless approach failed, trying regular signup', {
                error: (serverlessError as Error).message
              }, ipAddresses);
              
              // Approach 3: Regular signup
              const { data, error } = await supabase.auth.signUp({
                email,
                password,
                options: {
                  emailRedirectTo: `${window.location.origin}/confirm`,
                  data: {
                    email: email // Include email in user metadata as a backup
                  }
                }
              });
              
              // Store the data for use outside the try block
              signupData = data;
              
              // Log the raw response for debugging
              console.log('Regular signup response:', { data, error });
              log('Regular signup response', { 
                success: !error, 
                errorMessage: error?.message,
                errorCode: error?.code,
                userId: data?.user?.id
              }, ipAddresses);
              
              if (error) {
                // Check if the error is because the user already exists
                if (error.message.includes('already registered') || 
                    error.message.includes('already exists') ||
                    error.message.includes('User already registered')) {
                  throw new Error('An account with this email already exists. Please sign in instead.');
                }
                throw error;
              }
            }
          }
        } catch (signupError) {
          log('All signup methods failed', { 
            message: (signupError as Error).message,
            stack: (signupError as Error).stack
          }, ipAddresses);
          throw signupError;
        }
        
        // Check if user was created successfully
        if (signupData?.user) {
          log('User created successfully', { userId: signupData.user.id }, ipAddresses);
          
          // Ensure profile exists
          if (signupData.user.id && email) {
            try {
              const profileCreated = await ensureProfileExists(signupData.user.id, email);
              log('Profile creation result', { 
                success: profileCreated,
                userId: signupData.user.id,
                email: email
              }, ipAddresses);
              
              if (!profileCreated) {
                log('Profile creation failed', { 
                  userId: signupData.user.id,
                  email: email
                }, ipAddresses);
                console.error('Failed to create user profile');
              }
            } catch (profileError) {
              log('Profile creation error', { 
                error: (profileError as Error).message,
                stack: (profileError as Error).stack,
                userId: signupData.user.id
              }, ipAddresses);
              console.error('Error creating profile:', profileError);
            }
          }
          
          // Since email confirmation is disabled, we should have a session
          if (signupData.session) {
            // Email confirmation is disabled, proceed with login
            log('Session created, user logged in', {}, ipAddresses);
            onSuccess();
          } else {
            // If we don't have a session, show the confirmation screen anyway
            log('No session created, showing confirmation screen', {}, ipAddresses);
            setConfirmationSent(true);
          }
        } else {
          throw new Error('User account could not be created');
        }
      } else {
        // Sign in
        log('Attempting to sign in user', { email }, ipAddresses);
        
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        
        if (error) {
          log('Sign in failed', { 
            error: error.message,
            code: error.code
          }, ipAddresses);
          throw error;
        }
        
        // We should have a session now
        if (data?.session) {
          log('Sign in successful', { userId: data.session.user.id }, ipAddresses);
          
          // Ensure profile exists
          if (data.session.user.id && email) {
            const profileCreated = await ensureProfileExists(data.session.user.id, email);
            log('Profile creation result on sign in', { success: profileCreated }, ipAddresses);
          }
          onSuccess();
        } else {
          log('Failed to create session', {}, ipAddresses);
          throw new Error('Failed to create session');
        }
      }
    } catch (err) {
      const authError = err as AuthError;
      log('Auth error', { 
        message: authError.message, 
        code: authError.code 
      }, ipAddresses);
      setError(authError.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendConfirmation = async () => {
    if (resendCountdown > 0) return;
    
    setResendingEmail(true);
    setError(null);
    
    // Get client IP address for logging
    const ipAddresses = await getClientIpAddress();
    
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: signupEmail || email,
        options: {
          emailRedirectTo: `${window.location.origin}/confirm`,
        }
      });
      
      if (error) throw error;
      
      // Start countdown for resend button (30 seconds)
      setResendCountdown(30);
      log('Confirmation email resent', { email: signupEmail || email }, ipAddresses);
    } catch (err) {
      const authError = err as AuthError;
      log('Error resending confirmation', { message: authError.message }, ipAddresses);
      setError(`Failed to resend confirmation email: ${authError.message}`);
    } finally {
      setResendingEmail(false);
    }
  };

  const getInitialLetter = () => {
    return email.charAt(0).toUpperCase();
  };

  const getRandomColor = () => {
    // Generate a consistent color based on the email
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    // Use hue between 0 and 360, saturation 70%, lightness 60%
    const h = Math.abs(hash % 360);
    return `hsl(${h}, 70%, 60%)`;
  };

  if (resetSent) {
    return (
      <div className="text-center p-6 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg border border-teal-100">
        <h2 className="text-2xl font-bold text-teal-800 mb-4">Check Your Email</h2>
        <p className="text-teal-600 mb-4">
          We've sent you an email with instructions to reset your password.
        </p>
        <button
          onClick={() => {
            setResetPassword(false);
            setResetSent(false);
          }}
          className="text-coral-500 hover:text-coral-600 font-medium"
        >
          Back to login
        </button>
      </div>
    );
  }

  if (confirmationSent) {
    return (
      <div className="text-center p-6 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg border border-teal-100">
        <CheckCircle className="h-12 w-12 text-teal-600 mx-auto mb-4" />
        <h2 className="text-2xl font-bold text-teal-800 mb-4">Verify Your Email</h2>
        <div className="mb-6">
          <img 
            src="/beliefbuildr-icon.png" 
            alt="BeliefBuildr" 
            className="h-16 w-16 mx-auto mb-2"
          />
        </div>
        <p className="text-teal-600 mb-4">
          We've sent a confirmation email to <strong>{signupEmail || email}</strong>
        </p>
        <p className="text-teal-600 mb-6">
          Please check your inbox (and spam folder) and click the verification link to activate your account.
        </p>
        
        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-100 text-sm">
            {error}
          </div>
        )}
        
        <div className="flex flex-col space-y-3">
          <button
            onClick={handleResendConfirmation}
            disabled={resendingEmail || resendCountdown > 0}
            className="flex items-center justify-center bg-coral-500 text-white py-2 px-4 rounded-lg hover:bg-coral-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {resendingEmail ? (
              <Loader2 className="animate-spin h-5 w-5" />
            ) : (
              <>
                <RefreshCw className="h-5 w-5 mr-2" />
                {resendCountdown > 0 ? `Resend in ${resendCountdown}s` : 'Resend Confirmation Email'}
              </>
            )}
          </button>
          
          <button
            onClick={() => {
              setIsSignUp(false);
              setConfirmationSent(false);
            }}
            className="text-teal-600 hover:text-teal-800 font-medium"
          >
            Back to Sign In
          </button>
        </div>
      </div>
    );
  }

  if (signupSuccess) {
    return (
      <div className="text-center p-6 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg border border-teal-100">
        <h2 className="text-2xl font-bold text-teal-800 mb-4">Account Created!</h2>
        <p className="text-teal-600 mb-4">
          Your account has been created successfully. You can now sign in with your credentials.
        </p>
        <button
          onClick={() => {
            setIsSignUp(false);
            setSignupSuccess(false);
          }}
          className="bg-coral-500 text-white py-2 px-4 rounded-lg hover:bg-coral-600 transition-colors"
        >
          Go to Sign In
        </button>
      </div>
    );
  }

  return (
    <div className="w-full max-w-md">
      <form onSubmit={handleSubmit} className="bg-white/80 backdrop-blur-sm shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4 border border-teal-100">
        <h2 className="text-2xl font-bold text-teal-800 mb-6 text-center">
          {resetPassword ? 'Reset Password' : isSignUp ? 'Create Account' : 'Welcome Back'}
        </h2>

        {/* Profile Avatar Preview (only show when email is entered) */}
        {email && (
          <div className="flex justify-center mb-6">
            <div 
              className="h-20 w-20 rounded-full flex items-center justify-center text-white text-2xl font-bold"
              style={{ backgroundColor: getRandomColor() }}
            >
              {getInitialLetter()}
            </div>
          </div>
        )}

        <div className="mb-4">
          <label className="block text-teal-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-teal-400" size={20} />
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-teal-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-coral-300 focus:border-coral-300 bg-white/50"
              placeholder="Enter your email"
              required
            />
          </div>
        </div>

        {!resetPassword && (
          <div className="mb-6">
            <label className="block text-teal-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-teal-400" size={20} />
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full pl-10 pr-3 py-2 border border-teal-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-coral-300 focus:border-coral-300 bg-white/50"
                placeholder="Enter your password"
                required
                minLength={6}
              />
            </div>
            {isSignUp && (
              <p className="text-xs text-teal-600 mt-1">Password must be at least 6 characters long</p>
            )}
          </div>
        )}

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-100 text-sm">
            {error}
          </div>
        )}

        {isSignUp && (
          <div className="mb-4 p-3 bg-teal-50 text-teal-700 rounded-lg border border-teal-100 text-sm flex items-start">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5 text-teal-500" />
            <p>
              You'll need to verify your email address before you can use BeliefBuildr. 
              We'll send you a confirmation link after you sign up.
            </p>
          </div>
        )}

        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-coral-500 text-white py-2 px-4 rounded-lg hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-coral-300 focus:ring-offset-2 transition-colors shadow-md hover:shadow-lg flex items-center justify-center"
        >
          {isLoading ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <>
              {resetPassword ? 'Send Reset Instructions' : isSignUp ? 'Sign Up' : 'Sign In'}
              <ArrowRight className="ml-2" size={20} />
            </>
          )}
        </button>

        <div className="mt-4 text-center">
          {!resetPassword && (
            <button
              type="button"
              onClick={() => setIsSignUp(!isSignUp)}
              className="text-coral-500 hover:text-coral-600 text-sm font-medium"
            >
              {isSignUp ? 'Already have an account? Sign in' : "Don't have an account? Sign up"}
            </button>
          )}
        </div>

        <div className="mt-2 text-center">
          <button
            type="button"
            onClick={() => setResetPassword(!resetPassword)}
            className="text-teal-600 hover:text-teal-700 text-sm"
          >
            {resetPassword ? 'Back to login' : 'Forgot password?'}
          </button>
        </div>
        
        <div className="mt-4 text-center">
          <p className="text-gray-500 text-xs">v{APP_CONFIG.version}</p>
        </div>
      </form>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import { getAffirmationHistory } from '../../utils/storage';
import { decryptText } from '../../utils/crypto';
import { Download, Clock, X, Loader2, Image } from 'lucide-react';
import AffirmationImage from '../AffirmationImage';

interface AffirmationHistoryProps {
  onClose: () => void;
}

interface AffirmationEntry {
  id: string;
  user_id: string;
  encrypted_content: string;
  created_at: string;
  decrypted_content?: string;
}

export default function AffirmationHistory({ onClose }: AffirmationHistoryProps) {
  const [affirmations, setAffirmations] = useState<AffirmationEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState<'free' | 'standard' | 'premium'>('free');
  const [selectedAffirmation, setSelectedAffirmation] = useState<string | null>(null);
  const [affirmationImageUrl, setAffirmationImageUrl] = useState<string | null>(null);
  const [showImagePreview, setShowImagePreview] = useState(false);

  useEffect(() => {
    loadAffirmations();
  }, []);

  const loadAffirmations = async () => {
    try {
      setLoading(true);
      setError(null);

      // Get user ID for decryption
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      // Get user email for download filename
      const { data: profile } = await supabase
        .from('profiles')
        .select('email, subscription_plan')
        .eq('id', user.id)
        .single();
        
      if (profile?.email) {
        setUserEmail(profile.email);
      } else {
        setUserEmail(user.email);
      }

      // Set subscription plan
      if (profile?.subscription_plan) {
        setSubscriptionPlan(profile.subscription_plan as 'free' | 'standard' | 'premium');
      }

      // Get encrypted affirmations
      const history = await getAffirmationHistory();
      
      // Decrypt each affirmation
      const decryptedHistory = history.map(entry => ({
        ...entry,
        decrypted_content: decryptText(entry.encrypted_content, user.id)
      }));

      setAffirmations(decryptedHistory);
    } catch (err) {
      console.error('Error loading affirmation history:', err);
      setError('Failed to load your affirmation history. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    try {
      if (affirmations.length === 0) return;

      // Create CSV content with semicolon separator
      // Add header row
      let csvContent = "Date;Affirmation\n";
      
      // Add data rows
      affirmations.forEach(entry => {
        const date = new Date(entry.created_at).toLocaleString();
        // Escape quotes in the content and wrap in quotes to handle semicolons in the text
        const escapedContent = entry.decrypted_content ? 
          `"${entry.decrypted_content?.replace(/"/g, '""')}"` : 
          '""';
        csvContent += `${date};${escapedContent}\n`;
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      
      // Include user email in the filename if available
      const emailPart = userEmail ? `-${userEmail.replace(/@/g, '_at_')}` : '';
      a.download = `affirmation-history${emailPart}-${new Date().toISOString().slice(0, 10)}.csv`;
      
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error('Error downloading affirmations:', err);
      setError('Failed to download affirmation history');
    }
  };

  const handleImageGenerated = (dataUrl: string) => {
    setAffirmationImageUrl(dataUrl);
  };

  const handleDownloadImage = () => {
    if (!affirmationImageUrl) return;
    
    const link = document.createElement('a');
    link.href = affirmationImageUrl;
    link.download = `affirmation-${new Date().toISOString().slice(0, 10)}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShowImagePreview = (affirmation: string) => {
    setSelectedAffirmation(affirmation);
    setShowImagePreview(true);
  };

  const isPremium = subscriptionPlan === 'premium';

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50" onClick={onClose}>
      <div className="max-w-2xl w-full" onClick={e => e.stopPropagation()}>
        <div className="bg-white/90 backdrop-blur-sm rounded-lg shadow-xl p-8 border border-teal-100">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center">
              <Clock className="h-8 w-8 text-coral-500 mr-3" />
              <h1 className="text-2xl font-bold text-teal-800">Affirmation History</h1>
            </div>
            <button
              onClick={onClose}
              className="text-teal-400 hover:text-teal-600 transition-colors"
              aria-label="Close affirmation history"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-100">
              {error}
            </div>
          )}

          {loading ? (
            <div className="flex justify-center items-center p-8">
              <Loader2 className="animate-spin h-8 w-8 text-coral-500" />
            </div>
          ) : affirmations.length === 0 ? (
            <div className="text-center p-8 bg-gray-50 rounded-lg">
              <p className="text-teal-600">You haven't generated any affirmations yet.</p>
            </div>
          ) : (
            <>
              <div className="flex justify-end mb-4">
                <button
                  onClick={handleDownload}
                  className="flex items-center space-x-2 bg-coral-500 text-white px-4 py-2 rounded-lg hover:bg-coral-600 transition-colors"
                >
                  <Download className="h-5 w-5" />
                  <span>Download History (CSV)</span>
                </button>
              </div>
              
              <div className="space-y-4 max-h-[60vh] overflow-y-auto pr-2">
                {affirmations.map((entry) => (
                  <div key={entry.id} className="bg-teal-50 p-4 rounded-lg border border-teal-100">
                    <p className="text-teal-900 mb-2">{entry.decrypted_content}</p>
                    <div className="flex justify-between items-center">
                      <p className="text-xs text-teal-600">
                        {new Date(entry.created_at).toLocaleString()}
                      </p>
                      {isPremium && entry.decrypted_content && (
                        <button
                          onClick={() => handleShowImagePreview(entry.decrypted_content || '')}
                          className="text-xs text-coral-500 hover:text-coral-600 flex items-center"
                        >
                          <Image className="h-3 w-3 mr-1" />
                          Save as Image
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Hidden component to generate the image */}
      {selectedAffirmation && (
        <AffirmationImage 
          affirmation={selectedAffirmation} 
          onImageGenerated={handleImageGenerated} 
        />
      )}

      {/* Image Preview Modal */}
      {showImagePreview && affirmationImageUrl && (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center p-4 z-[60]" onClick={(e) => e.stopPropagation()}>
          <div className="bg-white rounded-lg shadow-xl p-4 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-teal-800">Affirmation Image</h3>
              <button 
                onClick={() => setShowImagePreview(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <div className="mb-4 overflow-hidden rounded-lg shadow-md">
              <img 
                src={affirmationImageUrl} 
                alt="Affirmation" 
                className="w-full h-auto"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleDownloadImage}
                className="bg-coral-500 text-white px-4 py-2 rounded-lg hover:bg-coral-600 transition-colors flex items-center"
              >
                <Download className="h-4 w-4 mr-2" />
                Download
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import { CheckCircle, AlertCircle, Loader2 } from 'lucide-react';
import { log } from '../../utils/storage';

const ConfirmationPage: React.FC = () => {
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState('Verifying your email...');

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        // Get the hash fragment from the URL
        const hash = window.location.hash;
        
        // Check if we have a hash (confirmation token)
        if (!hash) {
          setStatus('error');
          setMessage('No confirmation token found. Please check your email for the verification link.');
          return;
        }
        
        // Log the confirmation attempt
        log('Email confirmation attempt', { hash: hash.substring(0, 10) + '...' });
        
        // The hash contains the access token and refresh token
        // Supabase will automatically handle this when the page loads
        const { data, error } = await supabase.auth.getSession();
        
        if (error) {
          throw error;
        }
        
        if (data.session) {
          // Session exists, email is confirmed
          setStatus('success');
          setMessage('Your email has been verified successfully!');
          
          // Log successful confirmation
          log('Email confirmed successfully', { userId: data.session.user.id });
          
          // Redirect to home page after a delay
          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
        } else {
          throw new Error('Unable to verify email. Please try again or contact support.');
        }
      } catch (err) {
        console.error('Error confirming email:', err);
        setStatus('error');
        setMessage(err instanceof Error ? err.message : 'An error occurred during email verification.');
        
        // Log error
        log('Email confirmation error', { error: err instanceof Error ? err.message : 'Unknown error' });
      }
    };
    
    confirmEmail();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-sand-50 to-sand-100 flex items-center justify-center p-4">
      <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 max-w-md w-full text-center">
        {status === 'loading' && (
          <Loader2 className="h-12 w-12 text-coral-500 mx-auto mb-4 animate-spin" />
        )}
        
        {status === 'success' && (
          <CheckCircle className="h-12 w-12 text-green-500 mx-auto mb-4" />
        )}
        
        {status === 'error' && (
          <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
        )}
        
        <h2 className="text-2xl font-bold text-teal-800 mb-4">
          {status === 'loading' ? 'Verifying Email' : 
           status === 'success' ? 'Email Verified' : 
           'Verification Failed'}
        </h2>
        
        <div className="mb-6">
          <img 
            src="/beliefbuildr-icon.png" 
            alt="BeliefBuildr" 
            className="h-16 w-16 mx-auto mb-2"
          />
        </div>
        
        <p className="text-teal-600 mb-6">
          {message}
        </p>
        
        {status === 'error' && (
          <div className="flex flex-col space-y-3">
            <a 
              href="/"
              className="bg-coral-500 text-white py-2 px-4 rounded-lg hover:bg-coral-600 transition-colors"
            >
              Return to Home
            </a>
            
            <a 
              href="mailto:support@beliefbuildr.com"
              className="text-teal-600 hover:text-teal-800"
            >
              Contact Support
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationPage;
import { Question } from '../types';

export const initialQuestions: Question[] = [
  {
    text: "What are some of your goals, hopes and wishes?",
    tag: "hopesWishesGoals"
  },
  {
    text: "What do you see as obstacles?",
    tag: "obstacles"
  },
  {
    text: "How are you feeling today?",
    tag: "feelingToday"
  }
];
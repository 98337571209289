import OpenAI from 'openai';
import Anthropic from '@anthropic-ai/sdk';
import { GoogleGenerativeAI } from '@google/generative-ai';
import { supabase } from './supabase';
import { getSystemSettings } from './storage';
import { promptConfig } from '../config/prompts';
import type { UserResponse } from '../types';
import { log } from './storage';
import { isOfflineMode } from './supabase';

// AI Provider Clients
const createOpenAIClient = async () => {
  const settings = await getSystemSettings();
  if (!settings?.openai_key) {
    throw new Error('OpenAI API key not configured');
  }

  return new OpenAI({
    apiKey: settings.openai_key,
    dangerouslyAllowBrowser: true // Note: In production, API calls should go through a backend
  });
};

const createAnthropicClient = async () => {
  try {
    log('Starting Anthropic client creation...');
    const settings = await getSystemSettings();
    
    if (!settings?.anthropic_key) {
      log('Anthropic API key missing');
      throw new Error('Anthropic API key not configured');
    }

    // Log API key format check (safely)
    const keyLength = settings.anthropic_key.length;
    const hasValidPrefix = settings.anthropic_key.startsWith('sk-');
    log('Anthropic API key validation:', { 
      hasValidPrefix,
      keyLength,
      isString: typeof settings.anthropic_key === 'string'
    });
    
    if (!hasValidPrefix) {
      throw new Error('Invalid Anthropic API key format - must start with "sk-"');
    }

    log('Creating Anthropic client instance...');
    // Simplified client creation - more similar to official example
    const client = new Anthropic({
      apiKey: settings.anthropic_key,
      baseURL: 'https://api.anthropic.com'
    });

    // Test the client connection with a minimal request
    log('Testing Anthropic client connection...');
    try {
      const testResponse = await client.messages.create({
        model: 'claude-3-haiku-20240307',
        max_tokens: 1,
        messages: [{ role: 'user', content: 'test' }]
      });
      
      log('Anthropic test connection successful:', { 
        hasResponse: !!testResponse,
        hasContent: !!testResponse.content,
        contentLength: testResponse.content?.length
      });
    } catch (testError: any) {
      log('Anthropic test connection failed:', {
        error: testError.message,
        name: testError.name,
        status: testError.status,
        type: testError.type,
        cause: JSON.stringify(testError.cause)
      });

      // Enhance error messages based on specific failure modes
      if (testError.status === 401) {
        throw new Error('Invalid Anthropic API key - please check your credentials');
      } else if (testError.status === 403) {
        throw new Error('Anthropic API access forbidden - please verify your API key permissions');
      }
      
      throw testError;
    }

    log('Anthropic client creation successful');
    return client;
  } catch (error: any) {
    // Log detailed error information
    log('Anthropic client creation failed:', {
      error: error.message,
      name: error.name,
      status: error.status,
      type: error.type,
      cause: JSON.stringify(error.cause),
      stack: error.stack
    });

    // Rethrow with clear user-facing message
    throw new Error(`Failed to initialize Anthropic client: ${error.message}`);
  }
};

const createGeminiClient = async () => {
  const settings = await getSystemSettings();
  if (!settings?.gemini_key) {
    throw new Error('Google API key not configured');
  }

  return new GoogleGenerativeAI(settings.gemini_key);
};

const createDeepSeekClient = async () => {
  const settings = await getSystemSettings();
  if (!settings?.deepseek_key) {
    throw new Error('DeepSeek API key not configured');
  }

  return new OpenAI({
    apiKey: settings.deepseek_key,
    baseURL: 'https://api.deepseek.com/v1',
    dangerouslyAllowBrowser: true
  });
};

const formatPrompt = (responses: UserResponse, systemPrompt: string): string => {
  const goals = promptConfig.goalsFormat.replace('{text}', responses.hopesWishesGoals);
  const challenges = promptConfig.challengesFormat.replace('{text}', responses.obstacles);
  const currentState = promptConfig.currentStateFormat.replace('{text}', responses.feelingToday);

  return systemPrompt
    .replace('{goals}', goals)
    .replace('{challenges}', challenges)
    .replace('{currentState}', currentState);
};

// Provider-specific generation functions
const generateWithOpenAI = async (prompt: string, model: string): Promise<string> => {
  const openai = await createOpenAIClient();
  const completion = await openai.chat.completions.create({
    model,
    messages: [{ role: "user", content: prompt }],
    temperature: 0.7,
    max_tokens: 150,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0
  });

  return completion.choices[0]?.message?.content || '';
};

const generateWithAnthropic = async (prompt: string, model: string): Promise<string> => {
  try {
    log('Starting Anthropic generation...', { model });
    const anthropic = await createAnthropicClient();

    log('Sending request to Anthropic...', { 
      model,
      promptLength: prompt.length,
      maxTokens: 150
    });

    const response = await anthropic.messages.create({
      model,
      max_tokens: 150,
      messages: [{
        role: 'user',
        content: prompt
      }]
    });

    log('Received Anthropic response:', {
      hasContent: !!response?.content,
      contentLength: response?.content?.length,
      firstContentType: response?.content?.[0]?.type
    });

    // Validate response structure
    if (!response?.content || !Array.isArray(response.content) || response.content.length === 0) {
      throw new Error('Invalid response structure from Anthropic API');
    }

    // Extract text content
    const textContent = response.content[0]?.text;
    if (!textContent) {
      throw new Error('No text content in Anthropic response');
    }

    log('Successfully extracted Anthropic response text', {
      textLength: textContent.length
    });

    return textContent;
  } catch (error: any) {
    // Log detailed error information
    log('Anthropic generation failed:', {
      error: error.message,
      name: error.name,
      status: error.status,
      type: error.type,
      cause: JSON.stringify(error.cause),
      stack: error.stack
    });

    // Enhanced error handling with specific error types
    if (error.name === 'AnthropicError' || error.name === 'APIError') {
      throw new Error(`Anthropic API error: ${error.message}`);
    }
    if (error.status === 401) {
      throw new Error('Invalid Anthropic API key. Please check your API key in the system settings.');
    }
    if (error.status === 403) {
      throw new Error('Anthropic API access forbidden. Please verify your API key permissions.');
    }
    
    // If we get here, it's an unexpected error
    throw new Error(`Unable to generate affirmation: ${error.message || 'Unknown error'}`);
  }
};

const generateWithGemini = async (prompt: string, model: string): Promise<string> => {
  const genAI = await createGeminiClient();
  const geminiModel = genAI.getGenerativeModel({ model });
  const result = await geminiModel.generateContent(prompt);
  const response = await result.response;
  return response.text();
};

const generateWithDeepSeek = async (prompt: string, model: string): Promise<string> => {
  const deepseek = await createDeepSeekClient();
  const completion = await deepseek.chat.completions.create({
    model,
    messages: [{ role: "user", content: prompt }],
    temperature: 0.7,
    max_tokens: 150
  });

  return completion.choices[0]?.message?.content || '';
};

// Add a new function to handle provider fallback
const fallbackToOpenAI = async (prompt: string, error: Error): Promise<string> => {
  log('Attempting fallback to OpenAI due to error:', {
    originalError: error.message
  });

  const settings = await getSystemSettings();
  if (!settings?.openai_key) {
    throw new Error('OpenAI API key not configured for fallback');
  }

  // Use GPT-4 for fallback
  return generateWithOpenAI(prompt, 'gpt-4');
};

// Fallback affirmation for offline mode
const getOfflineAffirmation = (responses: UserResponse): string => {
  // Extract key themes from user responses
  const goals = responses.hopesWishesGoals.toLowerCase();
  const obstacles = responses.obstacles.toLowerCase();
  const feelings = responses.feelingToday.toLowerCase();
  
  // Check for common themes in goals
  let affirmation = '';
  
  // Default affirmation if we can't determine a specific one
  if (!affirmation) {
    affirmation = "I acknowledge my challenges today, but I have the strength and resilience to overcome them and move toward my goals.";
  }
  
  // Career/work related
  if (goals.includes('career') || goals.includes('job') || goals.includes('work') || goals.includes('professional')) {
    affirmation = "My professional journey is uniquely mine, and each step I take brings me closer to my career aspirations.";
  }
  
  // Health related
  if (goals.includes('health') || goals.includes('fitness') || goals.includes('exercise') || goals.includes('weight')) {
    affirmation = "I honor my body by making healthy choices today, knowing that small consistent actions lead to lasting well-being.";
  }
  
  // Relationship related
  if (goals.includes('relationship') || goals.includes('partner') || goals.includes('marriage') || goals.includes('family')) {
    affirmation = "I nurture my relationships with patience and understanding, creating space for deeper connections to flourish.";
  }
  
  // Financial related
  if (goals.includes('money') || goals.includes('financial') || goals.includes('wealth') || goals.includes('debt')) {
    affirmation = "I am creating financial abundance through my actions and decisions, moving steadily toward my goals.";
  }
  
  // Personal growth
  if (goals.includes('learn') || goals.includes('growth') || goals.includes('improve') || goals.includes('better')) {
    affirmation = "Every challenge I face is an opportunity for growth, and I embrace the lessons that come my way today.";
  }
  
  // Feeling anxious or stressed
  if (feelings.includes('anxious') || feelings.includes('stressed') || feelings.includes('overwhelmed') || feelings.includes('worried')) {
    affirmation = "I breathe deeply and release my anxiety, knowing that I have overcome challenges before and can do so again.";
  }
  
  // Feeling tired or exhausted
  if (feelings.includes('tired') || feelings.includes('exhausted') || feelings.includes('fatigued') || feelings.includes('drained')) {
    affirmation = "I give myself permission to rest and recharge, knowing that taking care of myself enables me to pursue my goals.";
  }
  
  // Feeling motivated or positive
  if (feelings.includes('motivated') || feelings.includes('inspired') || feelings.includes('excited') || feelings.includes('happy')) {
    affirmation = "I channel my positive energy into meaningful action, making the most of my motivation to move toward my dreams.";
  }
  
  return affirmation;
};

export const generateAffirmation = async (responses: UserResponse): Promise<string> => {
  try {
    log('Starting affirmation generation...');
    
    // Check if we're offline
    const offline = await isOfflineMode();
    if (offline) {
      log('Device appears to be offline, using offline affirmation generator');
      return getOfflineAffirmation(responses);
    }
    
    const settings = await getSystemSettings();
    if (!settings) {
      throw new Error('System settings not available');
    }

    log('Using AI provider:', { 
      provider: settings.active_provider,
      model: settings.model
    });

    const prompt = formatPrompt(responses, settings.system_prompt);
    let affirmation = '';

    try {
      switch (settings.active_provider) {
        case 'openai':
          if (!settings.openai_key?.startsWith('sk-')) {
            throw new Error('Please configure a valid OpenAI API key in the system settings.');
          }
          affirmation = await generateWithOpenAI(prompt, settings.model);
          break;
        case 'anthropic':
          if (!settings.anthropic_key?.startsWith('sk-')) {
            throw new Error('Please configure a valid Anthropic API key in the system settings.');
          }
          try {
            affirmation = await generateWithAnthropic(prompt, settings.model);
          } catch (anthropicError: any) {
            // If it's a connection error, try falling back to OpenAI
            if (anthropicError.message.includes('Failed to fetch')) {
              log('Anthropic connection error detected, attempting OpenAI fallback');
              affirmation = await fallbackToOpenAI(prompt, anthropicError);
            } else {
              throw anthropicError;
            }
          }
          break;
        case 'gemini':
          if (!settings.gemini_key) {
            throw new Error('Please configure a valid Google API key in the system settings.');
          }
          affirmation = await generateWithGemini(prompt, settings.model);
          break;
        case 'deepseek':
          if (!settings.deepseek_key) {
            throw new Error('Please configure a valid DeepSeek API key in the system settings.');
          }
          affirmation = await generateWithDeepSeek(prompt, settings.model);
          break;
        default:
          throw new Error('Invalid AI provider selected');
      }
    } catch (providerError: any) {
      // For any provider error, try OpenAI as a last resort if we haven't already
      if (
        settings.active_provider !== 'openai' && 
        settings.openai_key?.startsWith('sk-')
      ) {
        log('Provider error, attempting OpenAI fallback:', {
          originalProvider: settings.active_provider,
          error: providerError.message
        });
        affirmation = await fallbackToOpenAI(prompt, providerError);
      } else {
        throw providerError;
      }
    }

    if (!affirmation) {
      throw new Error('No affirmation was generated');
    }

    log('Successfully generated affirmation', {
      length: affirmation.length,
      provider: settings.active_provider
    });

    // Remove any quotes from the affirmation
    return affirmation.replace(/['"]/g, '');
  } catch (error) {
    log('Error in generateAffirmation:', {
      error: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : undefined
    });

    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to generate affirmation. Please try again later.');
  }
};

// Model lists for each provider
export const providerModels = {
  openai: [
    'gpt-4',
    'gpt-4o-mini',
    'gpt-4-turbo-preview',
    'gpt-3.5-turbo'
  ],
  anthropic: [
    'claude-3-opus-20240229',
    'claude-3-sonnet-20240229',
    'claude-3-haiku-20240307',
    'claude-2.1'
  ],
  gemini: [
    'gemini-pro',
    'gemini-pro-vision'
  ],
  deepseek: [
    'deepseek-chat',
    'deepseek-coder'
  ]
};
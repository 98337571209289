import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../../utils/supabase';
import { User, Clock, Mail, Calendar, Activity, X, Shield, Settings, Download, History, LogOut, Info, Upload, Camera, CreditCard } from 'lucide-react';
import { timeZones } from './timezones';
import { getLogs } from '../../utils/storage';
import AffirmationHistory from './AffirmationHistory';
import { APP_CONFIG } from '../../config/app';
import type { Profile } from '../../types';
import PricingPage from '../Subscription/PricingPage';

interface ProfilePageProps {
  onClose: () => void;
}

export default function ProfilePage({ onClose }: ProfilePageProps) {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [showAffirmationHistory, setShowAffirmationHistory] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [showPricingPage, setShowPricingPage] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    try {
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;
      if (!user) return;

      setUserEmail(user.email || null);

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setProfile(data);
    } catch (err) {
      console.error('Error loading profile:', err);
      setError('Failed to load profile');
    } finally {
      setLoading(false);
    }
  };

  const handleTimezoneChange = async (timezone: string) => {
    if (!profile) return;

    setSaving(true);
    setError(null);
    setSuccess(false);

    try {
      const { error } = await supabase
        .from('profiles')
        .update({ timezone })
        .eq('id', profile.id);

      if (error) throw error;

      setProfile({ ...profile, timezone });
      setSuccess(true);
    } catch (err) {
      console.error('Error updating timezone:', err);
      setError('Failed to update timezone');
    } finally {
      setSaving(false);
    }
  };

  const handleAdminClick = () => {
    window.location.href = '/admin';
    onClose();
  };

  const handleLogsClick = () => {
    window.location.href = '/logs';
    onClose();
  };

  const handleDownloadLogs = () => {
    try {
      const logs = getLogs();
      const timezone = profile?.timezone || 'UTC';
      
      // Create timestamp in user's timezone
      const now = new Date();
      const localTime = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
      
      // Format: YYYY-MM-DD_HH-MM-SS
      const timestamp = localTime.getFullYear() +
        '-' + String(localTime.getMonth() + 1).padStart(2, '0') +
        '-' + String(localTime.getDate()).padStart(2, '0') +
        '_' + String(localTime.getHours()).padStart(2, '0') +
        '-' + String(localTime.getMinutes()).padStart(2, '0') +
        '-' + String(localTime.getSeconds()).padStart(2, '0');
      
      const blob = new Blob([logs.join('\n')], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `beliefbuildr-logs-${timestamp}.txt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error('Error downloading logs:', err);
      setError('Failed to download logs');
    }
  };

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Redirect to home page after logout
      window.location.href = '/';
    } catch (err) {
      console.error('Error signing out:', err);
      setError('Failed to sign out. Please try again.');
      setLoggingOut(false);
    }
  };

  const handleProfileImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !profile) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please select an image file');
      return;
    }

    // Validate file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setError('Image size should be less than 2MB');
      return;
    }

    setUploadingImage(true);
    setError(null);
    setSuccess(false);

    try {
      // Upload image to Supabase Storage
      const fileName = `profile-${Date.now()}`;
      const { data, error: uploadError } = await supabase.storage
        .from('profile-images')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('profile-images')
        .getPublicUrl(fileName);

      // Update profile with image URL
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ profile_image_url: publicUrl })
        .eq('id', profile.id);

      if (updateError) throw updateError;

      // Update local state
      setProfile({ ...profile, profile_image_url: publicUrl });
      setSuccess(true);
    } catch (err) {
      console.error('Error uploading profile image:', err);
      setError('Failed to upload profile image. Please try again.');
    } finally {
      setUploadingImage(false);
      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const getInitialLetter = () => {
    const email = userEmail || profile?.email || '';
    return email.charAt(0).toUpperCase();
  };

  const getRandomColor = () => {
    // Generate a consistent color based on the user's email
    const email = userEmail || profile?.email || '';
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    // Use hue between 0 and 360, saturation 70%, lightness 60%
    const h = Math.abs(hash % 360);
    return `hsl(${h}, 70%, 60%)`;
  };

  const getSubscriptionPlanName = () => {
    if (!profile) return 'Free';
    
    switch (profile.subscription_plan) {
      case 'premium':
        return 'Premium';
      case 'standard':
        return 'Standard';
      default:
        return 'Free';
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-coral-500 border-t-transparent"></div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="text-center p-8 bg-white rounded-lg">
          <p className="text-teal-600">Please sign in to view your profile.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50" onClick={onClose}>
      <div className="max-w-2xl w-full" onClick={e => e.stopPropagation()}>
        <div className="bg-white/90 backdrop-blur-sm rounded-lg shadow-xl p-8 border border-teal-100">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center">
              <User className="h-8 w-8 text-coral-500 mr-3" />
              <h1 className="text-2xl font-bold text-teal-800">Profile Settings</h1>
            </div>
            <div className="flex items-center">
              <div className="text-xs text-gray-500 mr-3">
                v{APP_CONFIG.version}
              </div>
              <button
                onClick={onClose}
                className="text-teal-400 hover:text-teal-600 transition-colors"
                aria-label="Close profile"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
          </div>

          {/* Profile Image */}
          <div className="flex justify-center mb-6">
            <div className="relative">
              {profile.profile_image_url ? (
                <img 
                  src={profile.profile_image_url} 
                  alt="Profile" 
                  className="h-24 w-24 rounded-full object-cover border-2 border-coral-300"
                />
              ) : (
                <div 
                  className="h-24 w-24 rounded-full flex items-center justify-center text-white text-2xl font-bold"
                  style={{ backgroundColor: getRandomColor() }}
                >
                  {getInitialLetter()}
                </div>
              )}
              <button 
                onClick={handleProfileImageClick}
                className="absolute bottom-0 right-0 bg-coral-500 text-white p-2 rounded-full hover:bg-coral-600 transition-colors"
                title="Change profile picture"
              >
                <Camera className="h-4 w-4" />
              </button>
              <input 
                type="file" 
                ref={fileInputRef} 
                onChange={handleFileChange} 
                accept="image/*" 
                className="hidden" 
              />
            </div>
          </div>

          <div className="space-y-6">
            {/* Admin Status */}
            {(profile.is_superadmin || profile.is_admin) && (
              <div className="flex items-start space-x-3">
                <Shield className={`h-5 w-5 ${profile.is_superadmin ? 'text-indigo-600' : 'text-coral-400'} mt-1`} />
                <div>
                  <h2 className="text-sm font-medium text-teal-600">Role</h2>
                  <p className="mt-1 text-teal-900 font-medium">
                    {profile.is_superadmin ? 'Super Administrator' : 'Administrator'}
                  </p>
                  <div className="mt-2 flex space-x-4">
                    {profile.is_superadmin && (
                      <button
                        onClick={handleAdminClick}
                        className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-700 font-medium"
                      >
                        <Settings className="h-4 w-4 mr-1" />
                        Manage System Settings
                      </button>
                    )}
                    <button
                      onClick={handleLogsClick}
                      className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-700 font-medium"
                    >
                      <Settings className="h-4 w-4 mr-1" />
                      View User Logs
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Subscription Plan */}
            <div className="flex items-start space-x-3">
              <CreditCard className="h-5 w-5 text-coral-400 mt-1" />
              <div>
                <h2 className="text-sm font-medium text-teal-600">Subscription Plan</h2>
                <p className="mt-1 text-teal-900 font-medium">
                  {getSubscriptionPlanName()}
                </p>
                <button
                  onClick={() => setShowPricingPage(true)}
                  className="mt-2 inline-flex items-center text-sm text-coral-500 hover:text-coral-600 font-medium"
                >
                  <CreditCard className="h-4 w-4 mr-1" />
                  Manage Subscription
                </button>
              </div>
            </div>

            {/* Email */}
            <div className="flex items-start space-x-3">
              <Mail className="h-5 w-5 text-coral-400 mt-1" />
              <div>
                <h2 className="text-sm font-medium text-teal-600">Email Address</h2>
                <p className="mt-1 text-teal-900">{userEmail || profile.email || 'No email available'}</p>
              </div>
            </div>

            {/* Timezone */}
            <div className="flex items-start space-x-3">
              <Clock className="h-5 w-5 text-coral-400 mt-1" />
              <div className="flex-1">
                <h2 className="text-sm font-medium text-teal-600">Time Zone</h2>
                <select
                  value={profile.timezone || 'UTC'}
                  onChange={(e) => handleTimezoneChange(e.target.value)}
                  disabled={saving}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-teal-200 focus:outline-none focus:ring-2 focus:ring-coral-300 focus:border-coral-300 sm:text-sm rounded-md bg-white/50"
                >
                  {timeZones.map((tz) => (
                    <option key={tz.value} value={tz.value}>
                      {tz.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Last Affirmation */}
            <div className="flex items-start space-x-3">
              <Calendar className="h-5 w-5 text-coral-400 mt-1" />
              <div>
                <h2 className="text-sm font-medium text-teal-600">Last Affirmation</h2>
                <p className="mt-1 text-teal-900">
                  {profile.last_affirmation_at
                    ? new Date(profile.last_affirmation_at).toLocaleString(undefined, {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                      })
                    : 'No affirmations generated yet'}
                </p>
                <button
                  onClick={() => setShowAffirmationHistory(true)}
                  className="mt-2 inline-flex items-center text-sm text-coral-500 hover:text-coral-600 font-medium"
                >
                  <History className="h-4 w-4 mr-1" />
                  View Affirmation History
                </button>
              </div>
            </div>

            {/* Daily Usage */}
            <div className="flex items-start space-x-3">
              <Activity className="h-5 w-5 text-coral-400 mt-1" />
              <div>
                <h2 className="text-sm font-medium text-teal-600">Today's Usage</h2>
                <p className="mt-1 text-teal-900">
                  {profile.daily_usage_count || 0} affirmations generated today
                </p>
              </div>
            </div>

            {/* Download Logs */}
            <div className="flex items-start space-x-3">
              <Download className="h-5 w-5 text-coral-400 mt-1" />
              <div>
                <h2 className="text-sm font-medium text-teal-600">Debug Logs</h2>
                <button
                  onClick={handleDownloadLogs}
                  className="mt-1 text-coral-500 hover:text-coral-600 text-sm font-medium flex items-center"
                >
                  Download Logs
                </button>
              </div>
            </div>

            {/* App Version */}
            <div className="flex items-start space-x-3">
              <Info className="h-5 w-5 text-coral-400 mt-1" />
              <div>
                <h2 className="text-sm font-medium text-teal-600">App Version</h2>
                <p className="mt-1 text-teal-900">
                  {APP_CONFIG.name} v{APP_CONFIG.version}
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  Built on {new Date(APP_CONFIG.buildDate).toLocaleDateString()}
                </p>
              </div>
            </div>

            {/* Logout Button */}
            <div className="flex items-start space-x-3">
              <LogOut className="h-5 w-5 text-coral-400 mt-1" />
              <div>
                <h2 className="text-sm font-medium text-teal-600">Session</h2>
                <button
                  onClick={handleLogout}
                  disabled={loggingOut}
                  className="mt-1 text-red-500 hover:text-red-600 text-sm font-medium flex items-center"
                >
                  {loggingOut ? 'Signing out...' : 'Sign Out'}
                </button>
              </div>
            </div>
          </div>

          {error && (
            <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md text-sm border border-red-100">
              {error}
            </div>
          )}

          {success && (
            <div className="mt-4 p-3 bg-green-50 text-green-700 rounded-md text-sm border border-green-100">
              Settings saved successfully!
            </div>
          )}

          {uploadingImage && (
            <div className="mt-4 p-3 bg-blue-50 text-blue-700 rounded-md text-sm border border-blue-100 flex items-center">
              <div className="animate-spin rounded-full h-4 w-4 border-2 border-blue-500 border-t-transparent mr-2"></div>
              Uploading profile image...
            </div>
          )}
        </div>
      </div>

      {showAffirmationHistory && (
        <AffirmationHistory onClose={() => setShowAffirmationHistory(false)} />
      )}

      {showPricingPage && (
        <PricingPage onClose={() => setShowPricingPage(false)} />
      )}
    </div>
  );
}
import { PromptConfig } from '../types';

export const promptConfig: PromptConfig = {
  systemPrompt: `Generate a 1-2 sentence ‘Affirmation of The Day’ based on the user's responses about (1) their hopes, wishes, and/or goals, (2) what they see as their obstacles, and (3) how they feel today. Make it encouraging, empowering, and actionable while acknowledging their situation:

{goals}
{challenges}
{currentState}`,
  
  // Format strings for the different sections
  goalsFormat: "Goals: {text}",
  challengesFormat: "Challenges: {text}",
  currentStateFormat: "Current State: {text}"
};
import { createClient } from '@supabase/supabase-js';
import { log } from './storage';

// This is a workaround for the "Database error saving new user" issue
// It uses a server-side approach to create users directly

// In a production environment, this should be implemented as a serverless function
// to avoid exposing the service role key in the client
// This is just a temporary solution for development/testing

// WARNING: This approach is not secure for production use
// The service role key should never be exposed in client-side code

// For a production implementation, create a serverless function that:
// 1. Accepts email/password from the client
// 2. Creates the user using the service role key on the server
// 3. Returns the user data to the client

// Supabase URL is safe to expose
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;

// This should be a server-side secret in production
// For testing purposes only - this is the service role key
// In production, this would be stored securely on the server
const SUPABASE_SERVICE_KEY = import.meta.env.VITE_SUPABASE_SERVICE_KEY;

// Create a Supabase client with the service role key
const adminAuthClient = SUPABASE_SERVICE_KEY 
  ? createClient(supabaseUrl, SUPABASE_SERVICE_KEY, {
      auth: {
        autoRefreshToken: false,
        persistSession: false
      }
    })
  : null;

/**
 * Create a new user using the admin API
 * This bypasses some of the restrictions that might be causing the "Database error saving new user" issue
 */
export const adminCreateUser = async (email: string, password: string): Promise<any> => {
  if (!adminAuthClient) {
    log('Admin auth client not initialized', { error: 'Missing service key' });
    throw new Error('Admin auth client not initialized. Missing service key.');
  }

  try {
    log('Attempting to create user with admin API', { email });
    
    // Create the user with the admin API
  const { data, error } = await adminAuthClient.auth.admin.createUser({
    email,
    password,
    email_confirm: true, // Auto-confirm the email
    user_metadata: {} // Ensure user_metadata is initialized
  });
    
    if (error) {
      log('Admin API error creating user', { 
        error: error.message,
        code: error.code,
        status: error.status
      });
      throw error;
    }
    
    log('User created successfully with admin API', { 
      userId: data.user.id,
      email: data.user.email
    });
    
    return data;
  } catch (err: any) {
    log('Unexpected error in adminCreateUser', { 
      message: err.message,
      stack: err.stack
    });
    throw err;
  }
};

/**
 * Sign in a user after they've been created with the admin API
 */
export const signInAfterAdminCreate = async (email: string, password: string) => {
  if (!adminAuthClient) {
    throw new Error('Admin auth client not initialized');
  }
  
  try {
    // Sign in the user
    const { data, error } = await adminAuthClient.auth.signInWithPassword({
      email,
      password
    });
    
    if (error) throw error;
    
    return data;
  } catch (err) {
    log('Error signing in after admin create', { 
      error: (err as Error).message
    });
    throw err;
  }
};
